import React, { Fragment } from 'react'
import ContactCard, { IContactType, IWeblinks } from 'components/ContactCard'

import styles from './ContactsSection.scss'

import { types } from './specs'

export interface IContactsSection {
  websiteLinks: IWeblinks
  phones: Array<string>
  emails: Array<string>
  isNFT: boolean
  isInfluencers?: boolean
  addresses?: Array<string>
  wallets?: Array<string>
}

function ContactsSection({
  websiteLinks,
  phones,
  emails,
  isNFT = false,
  addresses,
  isInfluencers = false,
  wallets,
}: IContactsSection) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.employees}>
          {types.map(type => (
            <Fragment key={type}>
              {(type !== 'addresses' || (type === 'addresses' && isInfluencers)) && (
                <div className={styles.employee}>
                  <ContactCard
                    type={type as IContactType}
                    websiteLinks={websiteLinks}
                    phones={phones}
                    emails={emails}
                    addresses={addresses}
                    isNFT={isNFT}
                    isInfluencers={isInfluencers}
                    wallets={wallets}
                  />
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContactsSection
