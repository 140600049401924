import React from 'react'
import PropTypes from 'prop-types'
import { Alert as ReactstrapAlert } from 'reactstrap'

const allowedTypes = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
const [TYPE_PRIMARY] = allowedTypes

const Alert = ({ type, message, onDismiss }) => (
  <ReactstrapAlert color={type} isOpen toggle={onDismiss}>
    {message}
  </ReactstrapAlert>
)

Alert.propTypes = {
  type: PropTypes.oneOf(allowedTypes),
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func,
}

Alert.defaultProps = {
  type: TYPE_PRIMARY,
  onDismiss: () => {},
}

export default Alert
