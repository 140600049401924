import { VerticalEntity } from '../../../../store/verticals/types'
import { Period } from '../../../../utils/types'
import moment from 'moment'

const numberIsBetween = (value: number, start: number, end: number) => value >= start && value <= end

export const getLabelsForFinancialOverview = () => [
  { key: 'total', value: 'Total revenue' },
  { key: 'withoutVat', value: 'Total w/o VAT' },
  { key: 'reported', value: 'Reported' },
  { key: 'unreported', value: 'Unreported' },
]

const getYearFromPeriod = (period: Period) => {
  const startYear = moment(period.start).year()
  const endYear = moment(period.end).year()

  return {
    startYear,
    endYear,
  }
}

export const getVerticalRevenueByTotalFromEntity = (entityData: VerticalEntity | null, period: Period) => {
  if (!entityData) return []

  const { startYear, endYear } = getYearFromPeriod(period)

  let exactSum = 0
  let documentedSum = 0

  entityData.revenueSources.forEach(source => {
    const exactData = source.revenueByYear?.exact
    const documentedData = source.revenueByYear?.documented

    for (const key in exactData) {
      if (
        Object.prototype.hasOwnProperty.call(exactData, key) &&
        numberIsBetween(parseFloat(key), startYear, endYear)
      ) {
        exactSum += exactData[key]
      }
    }

    for (const key in documentedData) {
      if (
        Object.prototype.hasOwnProperty.call(documentedData, key) &&
        numberIsBetween(parseFloat(key), startYear, endYear)
      ) {
        documentedSum += documentedData[key]
      }
    }
  })

  return [
    { name: 'Exact', value: exactSum },
    { name: 'Documented', value: documentedSum },
  ]
}
