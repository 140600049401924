import React, { FC, useMemo } from 'react'
import GenericText from 'components/common/GenericText'
import { useTheme } from '@mui/material/styles'
import { TABLE_TEXT_TYPES } from 'utils/types'

interface TableTextProps {
  children: React.ReactNode
  textType?: TABLE_TEXT_TYPES
}

const TableText: FC<TableTextProps> = ({ children, textType = TABLE_TEXT_TYPES.default }) => {
  const theme = useTheme()

  const stylesByTextType = useMemo(() => {
    return {
      [TABLE_TEXT_TYPES.default]: {
        fontSize: theme.typography.CustomFontSize.tableText,
        fontWeight: 400,
      },
      [TABLE_TEXT_TYPES.columnHeader]: {
        fontSize: theme.typography.CustomFontSize.tableText,
        fontWeight: 400,
        color: theme.palette.textColor.tableColumnHeader,
      },
      [TABLE_TEXT_TYPES.groupHeader]: {
        fontSize: theme.typography.CustomFontSize.tableGroupHeader,
        letterSpacing: '1px',
        fontWeight: 600,
        color: theme.palette.textColor.tableGroupHeader,
      },
    }
  }, [theme])

  return (
    <GenericText withTitle={false} sx={stylesByTextType[textType]}>
      {children}
    </GenericText>
  )
}

export default TableText
