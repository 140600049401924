import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Button as ReactstrapButton } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import './Button.scss'

export interface IButton {
  className: string
  to?: string
  href?: string
  children?: ReactNode
  onClick?: () => void
  color?: string
  outline?: any | undefined
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export default function Button(props: IButton) {
  const { to, href, className } = props

  const tag: any = to ? NavLink : href ? 'a' : 'button'

  const nextProps = { ...props, tag, className: cx(className) }
  return <ReactstrapButton {...nextProps} />
}
