import React, { FC } from 'react'

import GenericText from 'components/common/GenericText'

import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'

const valuesStyles = {
  display: 'inline-block',
  fontWeight: 600,
  fontSize: 'inherit',
  fontFamily: 'Dosis',
}

interface PaginationCountersProps {
  countMessages: FormattedMessage.MessageDescriptor
  displayedCount: number | null
  totalCount: number | null
}

const PaginationCounters: FC<PaginationCountersProps> = ({ countMessages, totalCount, displayedCount }) => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        ...theme.mixins.textTruncate(),
        fontSize: '1.25rem',
        textTransform: 'uppercase',
      }}
    >
      <FormattedMessage
        {...countMessages}
        values={{
          displayed: <GenericText sx={valuesStyles}>1 - {displayedCount || 'N/A'}</GenericText>,
          total: (
            <GenericText sx={valuesStyles}>{totalCount ? <FormattedNumber value={totalCount} /> : 'N/A'}</GenericText>
          ),
        }}
      />
    </Stack>
  )
}

export default PaginationCounters
