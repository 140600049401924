import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'
import ConditionalWrapper from 'components/ConditionalWrapper'
import FormattedLargeNumber from 'components/FormattedLargeNumber'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import { PieLegendPropType } from './props'
import styles from './PieChartLegends.scss'

const allowedTypes = ['percent', 'currency']
const [TYPE_PERCENT, TYPE_CURRENCY] = allowedTypes

const allowedShapes = ['square', 'circle']
const [SHAPE_SQUARE, SHAPE_CIRCLE] = allowedShapes

const allowedDirections = ['row', 'column']
const [DIRECTION_ROW] = allowedDirections

export const PieChartLegends = ({ type, direction, shape, isTitled, legends, className }) => (
  <ul className={cx(styles.legends, styles[direction], className)}>
    {legends.map(({ id, value, icon }) => (
      <li key={id} className={cx(styles.legend, { [styles.titled]: isTitled })}>
        <span className={cx(styles.shape, styles[shape])}>
          {shape === SHAPE_CIRCLE && <span className={styles.inner}>○</span>}
        </span>
        <span className={cx(styles.wrapper, { [styles.titled]: isTitled })}>
          <span className={styles.value}>
            {type === TYPE_PERCENT && <FormattedNumber value={value} style={type} />}
            {type === TYPE_CURRENCY && <FormattedLargeNumber value={value} />}
          </span>
          <ConditionalWrapper
            condition={!!icon}
            wrapper={children => (
              <Icon name={icon} direction='left' size='medium' className={styles.icon}>
                {children}
              </Icon>
            )}
          >
            <span className={styles.label}>
              <Tooltip title={id}>{id}</Tooltip>
            </span>
          </ConditionalWrapper>
        </span>
      </li>
    ))}
  </ul>
)

PieChartLegends.propTypes = {
  isTitled: PropTypes.bool,
  type: PropTypes.oneOf(allowedTypes),
  direction: PropTypes.oneOf(allowedDirections),
  shape: PropTypes.oneOf(allowedShapes),
  legends: PropTypes.arrayOf(PieLegendPropType).isRequired,
  className: PropTypes.string,
}

PieChartLegends.defaultProps = {
  isTitled: false,
  type: TYPE_PERCENT,
  direction: DIRECTION_ROW,
  shape: SHAPE_SQUARE,
}
