import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from 'services/api'
import { setGlobalLoading, fetchOrganizations, fetchUsers, fetchZones } from 'store/global'

const SLICE_KEY = 'organizations'

export const submitOrganization = createAsyncThunk(
  `${SLICE_KEY}/submitOrganization`,
  async ({ organization }, { dispatch }) => {
    dispatch(setGlobalLoading(true))

    const data = await apiService.submitOrganization(organization)

    dispatch(setGlobalLoading(false))

    dispatch(fetchOrganizations())

    return data
  },
)

export const deleteOrganization = createAsyncThunk(
  `${SLICE_KEY}/deleteOrganization`,
  async ({ organizationId }, { dispatch }) => {
    dispatch(setGlobalLoading(true))

    const data = await apiService.deleteOrganization(organizationId)

    dispatch(setGlobalLoading(false))

    dispatch(fetchOrganizations())

    return data
  },
)

export const submitUser = createAsyncThunk(`${SLICE_KEY}/submitUser`, async ({ user }, { dispatch }) => {
  dispatch(setGlobalLoading(true))

  const data = await apiService.submitUser(user)

  dispatch(setGlobalLoading(false))

  dispatch(fetchUsers())

  return data
})

export const createUsersBulk = createAsyncThunk(`${SLICE_KEY}/createUsersBulk`, async ({ users }, { dispatch }) => {
  dispatch(setGlobalLoading(true))

  const data = await apiService.createBulkUsers(users)

  dispatch(setGlobalLoading(false))
  dispatch(fetchUsers())

  return data
})

export const deleteUser = createAsyncThunk(`${SLICE_KEY}/deleteUser`, async ({ email }, { dispatch }) => {
  dispatch(setGlobalLoading(true))

  const data = await apiService.deleteUser(email)

  dispatch(setGlobalLoading(false))

  dispatch(fetchUsers())

  return data
})

export const submitZone = createAsyncThunk(`${SLICE_KEY}/submitZone`, async ({ zone }, { dispatch }) => {
  dispatch(setGlobalLoading(true))

  const data = await apiService.submitZone(zone)

  dispatch(setGlobalLoading(false))

  dispatch(fetchZones())

  return data
})

export const deleteZone = createAsyncThunk(`${SLICE_KEY}/deleteZone`, async ({ zoneId }, { dispatch }) => {
  dispatch(setGlobalLoading(true))

  const data = await apiService.deleteZone(zoneId)

  dispatch(setGlobalLoading(false))

  dispatch(fetchZones())

  return data
})

export const initialState = {
  displayedOrganization: {
    isOpen: false,
  },
  displayedUser: {
    isOpen: false,
  },
  displayedZone: {
    isOpen: false,
  },
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setDisplayedOrganization(state, { payload = {} }) {
      state.displayedOrganization = payload.organization || { isOpen: false }
    },
    updateDisplayedOrganization(state, { payload = {} }) {
      const { key, value } = payload

      state.displayedOrganization = {
        ...state.displayedOrganization,
        [key]: value,
      }
    },
    closeDisplayedOrganization(state) {
      state.displayedOrganization = {
        ...state.displayedOrganization,
        isOpen: false,
      }
    },
    setDisplayedUser(state, { payload = {} }) {
      state.displayedUser = payload.user || { isOpen: false }
    },
    updateDisplayedUser(state, { payload = {} }) {
      const { key, value } = payload

      state.displayedUser = {
        ...state.displayedUser,
        [key]: value,
      }
    },
    closeDisplayedUser(state) {
      state.displayedUser = {
        ...state.displayedUser,
        isOpen: false,
      }
    },
    setDisplayedZone(state, { payload = {} }) {
      state.displayedZone = payload.zone || { isOpen: false }
    },
    updateDisplayedZone(state, { payload = {} }) {
      const { key, value } = payload

      state.displayedZone = {
        ...state.displayedZone,
        [key]: value,
      }
    },
    closeDisplayedZone(state) {
      state.displayedZone = {
        ...state.displayedZone,
        isOpen: false,
      }
    },
  },
})

export const {
  setDisplayedOrganization,
  updateDisplayedOrganization,
  closeDisplayedOrganization,
  setDisplayedUser,
  updateDisplayedUser,
  closeDisplayedUser,
  setDisplayedZone,
  updateDisplayedZone,
  closeDisplayedZone,
} = slice.actions

export const organizationsReducer = slice.reducer
export default slice
