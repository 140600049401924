import moment from 'moment'

const DATE_FORMATS = ['MMMM DD, YYYY', 'MMM DD']
export const [DATE_FORMAT_FULL, DATE_FORMAT_NO_YEAR] = DATE_FORMATS

const ALLOWED_FORMATS = [
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss.SSS',
  'YYYY-MM-DD',
  'MMMM DD, YYYY',
  'YYYY/MM/DD',
]

export const formattedDate = (date: string | Date, format = DATE_FORMAT_FULL) =>
  moment(date, ALLOWED_FORMATS).format(format)

export const formattedZoneName = (zone: string) => {
  const zoneName = zone.toLowerCase()

  if (zoneName.startsWith('us/')) return `US/${zoneName.slice(3)}`

  return zoneName
}

export const formatNumber = (
  number: number | null,
  currentLocales?: string | null,
  options?: Intl.NumberFormatOptions,
) => {
  if (number === null || number === undefined) return 'N/A'
  const defaultOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }
  const locales = currentLocales || 'en-GB'
  return new Intl.NumberFormat(locales, {
    ...defaultOptions,
    ...options,
  }).format(number)
}
