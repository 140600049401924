import React, { FC, useState } from 'react'

import PaginationCounters from 'components/verticals/TableActionsBar/PaginationCounters'
import DashedLine from 'components/verticals/TableActionsBar/DashedLine'
import ExportCSVButton from 'components/verticals/TableActionsBar/ExportCSVButton'

import messages from './messages'

import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { FormattedMessage } from 'react-intl'

import { downloadCSV } from 'utils/downloadCSV'
import { _source } from 'utils/csvConfig'
import { exportEntitiesCSV } from 'services/verticalsTablesApi'

const containerStyles: SxProps = {
  flexDirection: 'row',
  paddingBlockEnd: '1.125rem',
  justifyContent: 'space-between',
  alignItems: 'center',
}

interface TableActionBarsProps {
  countMessages: FormattedMessage.MessageDescriptor
  displayedCount: number | null
  totalCount: number | null
  zoneEndpoint: string
  csvFileName: string
  type: string
}

const TableActionsBars: FC<TableActionBarsProps> = ({
  countMessages,
  displayedCount,
  totalCount,
  zoneEndpoint,
  csvFileName,
  type
}) => {
  const [isExportDisabled, setIsExportDisabled] = useState(false)
  const { trackEvent } = useMatomo()

  const onCSVExport = async () => {
    setIsExportDisabled(true)
    const entityCSV = await exportEntitiesCSV(zoneEndpoint, _source, type)
    setIsExportDisabled(false)
    downloadCSV(entityCSV, csvFileName)
    trackEvent({ category: 'Export', action: 'User requested for entity CSV' })
  }

  return (
    <Stack sx={containerStyles}>
      <PaginationCounters countMessages={countMessages} displayedCount={displayedCount} totalCount={totalCount} />
      <DashedLine />
      <ExportCSVButton exportMessages={messages.export} isExportDisabled={isExportDisabled} onCSVExport={onCSVExport} />
    </Stack>
  )
}

export default TableActionsBars
