import React, { useRef, useEffect } from 'react'
import usePrevious from '@rooks/use-previous'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import VirtualizedGrid from 'react-virtualized/dist/commonjs/MultiGrid'
import RowLoader from 'components/RowLoader'
import messages from './messages'
import styles from './Grid.scss'

export function Grid(props) {
  const {
    isLoading,
    withBoxShadow,
    rows: items,
    selectedRows,
    highlightedRow,
    columns,
    fixedRowCount,
    fixedColumnCount,
    children: renderCell,
    rowHeight,
    headerRowHeight,
    height = Math.min(props.headerRowHeight * 1.5 + props.rowHeight * props.rows.length, props.maxHeight),
    width: fixedGridWidth,
    withGroupHeaders,
    withHeaders,
    onCellClick,
    className,
    innerClassName,
    onHeaderClick = () => {},
  } = props

  const gridRef = useRef(null)
  const previousColumns = usePrevious(columns)
  const previousFixedGridWidth = usePrevious(fixedGridWidth)

  /**
    VirtualizedGrid: Public Methods
    ---
    measureAllCells: Pass-thru that calls measureAllCells on all child Grids
    recomputeGridSize: Pass-thru that calls recomputeGridSize on all child Grids
    forceUpdateGrids: Pass-thru that calls forceUpdate on all child Grids
  */
  const forceUpdate = () => {
    if (gridRef && gridRef.current) {
      gridRef.current.measureAllCells()
      gridRef.current.recomputeGridSize()
      gridRef.current.forceUpdateGrids()
    }
  }

  useEffect(() => {
    const areColumnsChanged = !!previousColumns && columns.length !== previousColumns.length
    if (areColumnsChanged) forceUpdate()
  }, [columns])

  useEffect(() => {
    const isFixedGridWidthChanged = !!previousFixedGridWidth && fixedGridWidth !== previousFixedGridWidth
    if (isFixedGridWidthChanged) forceUpdate()
  }, [fixedGridWidth])

  let rows = []
  if (withGroupHeaders) rows.push({})
  if (withHeaders) rows.push({})

  rows = [...rows, ...items]

  const overscanRowCount = 4

  const placeholder = () => (
    <div className={styles.noResults}>
      <FormattedMessage {...messages.noResults} />
    </div>
  )

  const cellRenderer = ({ key, rowIndex, columnIndex, style }) => {
    const currentItem = rows[rowIndex] || {}
    const { id: currentItemId = -1 } = currentItem

    const isSelected = selectedRows.includes(currentItemId)
    const isHighlighted = highlightedRow === currentItemId

    const currentColumn = columns[columnIndex]

    const isGroupHeaderCell = withGroupHeaders && rowIndex === 0
    const isHeaderCell = withHeaders && ((!withGroupHeaders && rowIndex === 0) || (withGroupHeaders && rowIndex === 1))
    const isContentCell = !isGroupHeaderCell && !isHeaderCell

    return (
      <span
        key={key}
        style={style}
        onClick={
          isHeaderCell
            ? columns[columnIndex]?.isSortable
              ? () => onHeaderClick(columns[columnIndex].key)
              : undefined
            : onCellClick
            ? () => onCellClick(columnIndex, currentItem)
            : undefined
        }
        className={cx(styles.cell, isHeaderCell ? currentColumn.headerClassName : currentColumn.columnClassName, {
          [styles.groupHeader]: isGroupHeaderCell && currentColumn.groupHeader,
          [styles.header]: isHeaderCell,
          [styles.content]: isContentCell,
          [styles.firstInGroup]: currentColumn.firstInGroup,
          [styles.selected]: isSelected,
          [styles.highlighted]: isHighlighted,
          [styles.separated]: !fixedRowCount,
          [styles.clickable]: (!isHeaderCell && !!onCellClick) || (isHeaderCell && columns[columnIndex]?.isSortable),
        })}
      >
        {isLoading && <RowLoader />}
        {!isLoading && (
          <>
            {isGroupHeaderCell && currentColumn.groupHeader && (
              <h4 className={styles.primaryTitle}>{currentColumn.groupHeader}</h4>
            )}
            {isHeaderCell && renderCell({}, columns[columnIndex], rowIndex, true)}
            {isContentCell && renderCell(rows[rowIndex], columns[columnIndex], rowIndex)}
          </>
        )}
      </span>
    )
  }

  const sumAllColumnsSizes = columns.reduce((acc, { size = 1 }) => acc + size, 0)

  const getColumnWidth = (columnIndex, gridWidth) => {
    const currentColumn = columns[columnIndex]
    const { size = 1, minWidth = 100, fixedWidth } = currentColumn

    if (fixedWidth) return fixedWidth

    const relativeSize = (size / sumAllColumnsSizes) * gridWidth
    return Math.max(relativeSize, minWidth)
  }

  const getRowHeight = ({ index: rowIndex }) => {
    const isGroupHeaderRow = withGroupHeaders && rowIndex === 0
    const isHeaderRow = withHeaders && ((!withGroupHeaders && rowIndex === 0) || (withGroupHeaders && rowIndex === 1))

    if (isGroupHeaderRow || isHeaderRow) return headerRowHeight
    return rowHeight
  }

  const withFixedColumns = fixedColumnCount > 0

  return (
    <div
      className={cx(styles.container, className, {
        [styles.noShadow]: !withBoxShadow,
      })}
    >
      <AutoSizer disableHeight>
        {({ width }) => (
          <VirtualizedGrid
            ref={gridRef}
            enableFixedRowScroll={!!fixedRowCount}
            enableFixedColumnScroll={withFixedColumns}
            fixedRowCount={fixedRowCount}
            fixedColumnCount={fixedColumnCount}
            hideTopRightGridScrollbar
            width={fixedGridWidth || width}
            height={height}
            noContentRenderer={placeholder}
            rowCount={rows.length}
            rowHeight={getRowHeight}
            overscanRowCount={overscanRowCount}
            overscanColumnCount={0}
            columnCount={columns.length}
            columnWidth={({ index }) => getColumnWidth(index, fixedGridWidth || width)}
            cellRenderer={cellRenderer}
            classNameBottomLeftGrid={styles.fixedColumns}
            classNameBottomRightGrid={cx(styles.contentGrid, innerClassName, {
              [styles.noScroll]: withFixedColumns,
            })}
            classNameTopLeftGrid={styles.fixedColumnsHeaders}
            classNameTopRightGrid={styles.headerRows}
          />
        )}
      </AutoSizer>
    </div>
  )
}

Grid.propTypes = {
  isLoading: PropTypes.bool,
  withBoxShadow: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  selectedRows: PropTypes.array,
  highlightedRow: PropTypes.string,
  fixedRowCount: PropTypes.number,
  fixedColumnCount: PropTypes.number,
  columns: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  headerRowHeight: PropTypes.number,
  withGroupHeaders: PropTypes.bool,
  withHeaders: PropTypes.bool,
  onCellClick: PropTypes.func,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  onHeaderClick: PropTypes.func,
}

Grid.defaultProps = {
  isLoading: false,
  withBoxShadow: true,
  withGroupHeaders: false,
  withHeaders: false,
  selectedRows: [],
  fixedRowCount: 0,
  fixedColumnCount: 0,
  maxHeight: 1000,
  rowHeight: 60,
  headerRowHeight: 40,
}
