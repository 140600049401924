import { BaseFilter, DISPLAY_AS_OPTIONS, DateRange, Picker, RENTALS_KEYS, TYPES_OF_SHAPE } from './common'
import { gridColumns as rentalsSearchableFields } from '../../components/rentals/RentalsHosts/specs'
import moment from 'moment'

const {
  ZONE_TYPE,
  RENTALS_SEARCH,
  RENTALS_SEARCHFIELDS,
  SORT,
  NUMBER_OF_LISTINGS,
  HOST_ID,
  JOINED_IN,
  HOST_TYPE,
  NESTED_HOST_LOCATIONS,
  NESTED_LISTING_LOCATIONS,
} = RENTALS_KEYS

export interface NestedOptions {
  [key: string]: string[]
}

export interface FixedNestedOptions {
  [key: string]: { groupOptions: string[]; itemSelections: string[] }
}

export interface RentalsZoneType extends BaseFilter<RENTALS_KEYS.ZONE_TYPE, Picker> {
  displayAs: DISPLAY_AS_OPTIONS.RANGE
}

export type RentalsSearch = BaseFilter<RENTALS_KEYS.RENTALS_SEARCH, string>

export type RentalsSearchfields = BaseFilter<RENTALS_KEYS.RENTALS_SEARCHFIELDS, string[]>

export type RentalsSort = BaseFilter<RENTALS_KEYS.SORT, string>

export interface RentalsUsableFilters {
  zoneType: Picker
  listingAddress: string[]
  search: string
  searchFields: string[]
  sort: string
  numberOfListings: Picker
  joinedIn: DateRange
  hostType: string[]
}

export interface RentalsNumberOfListings extends BaseFilter<RENTALS_KEYS.NUMBER_OF_LISTINGS, Picker> {
  displayAs: DISPLAY_AS_OPTIONS.RANGE
}

export interface RentalsHostId extends BaseFilter<RENTALS_KEYS.HOST_ID, string> {
  displayAs: DISPLAY_AS_OPTIONS.INPUT
  isSearch: boolean
}

export interface RentalsJoinedIn extends BaseFilter<RENTALS_KEYS.JOINED_IN, DateRange> {
  displayAs: DISPLAY_AS_OPTIONS.MONTH_PICKER
}

export interface RentalsHostType extends BaseFilter<RENTALS_KEYS.HOST_TYPE, string[]> {
  displayAs: DISPLAY_AS_OPTIONS.CHECKBOXES
  options: string[]
  typeOfShape: TYPES_OF_SHAPE.REGULAR
  isRow: boolean
  withIcons: boolean
}

export interface RentalsNestedLocations
  extends BaseFilter<RENTALS_KEYS.NESTED_LISTING_LOCATIONS | RENTALS_KEYS.NESTED_HOST_LOCATIONS, string[]> {
  displayAs: DISPLAY_AS_OPTIONS.NESTED_MULTI_SELECT
  options: NestedOptions
  disableSearch: boolean
  hasSelectAll: boolean
}

export type RentalsFilters = [
  RentalsZoneType,
  RentalsSearch,
  RentalsSearchfields,
  RentalsSort,
  RentalsNumberOfListings,
  RentalsHostId,
  RentalsJoinedIn,
  RentalsHostType,
  RentalsNestedLocations,
  RentalsNestedLocations,
]

const { CHECKBOXES, RANGE, MONTH_PICKER, INPUT, NESTED_MULTI_SELECT } = DISPLAY_AS_OPTIONS
const { REGULAR } = TYPES_OF_SHAPE

export const rentalsFilters: RentalsFilters = [
  {
    key: ZONE_TYPE,
    displayAs: RANGE,
    initialValue: { picker: 'Picker' },
  },
  {
    key: RENTALS_SEARCH,
    initialValue: '',
  },
  {
    key: RENTALS_SEARCHFIELDS,
    initialValue: rentalsSearchableFields.filter(column => column.isSearchable).map(column => column.key),
  },
  {
    key: SORT,
    initialValue: 'numberOfListings',
  },
  {
    key: NUMBER_OF_LISTINGS,
    displayAs: RANGE,
    initialValue: { picker: 'Picker' },
  },
  {
    key: HOST_ID,
    displayAs: INPUT,
    initialValue: '',
    isSearch: false,
  },
  {
    key: JOINED_IN,
    displayAs: MONTH_PICKER,
    initialValue: {
      start: '2007/01/01',
      end: moment().format('YYYY/MM/DD'),
    },
  },
  {
    key: HOST_TYPE,
    displayAs: CHECKBOXES,
    options: ['Person', 'Company'],
    initialValue: [],
    typeOfShape: REGULAR,
    isRow: true,
    isActivated: String(process.env.DEMO_MODE).toLowerCase() === 'true',
    withIcons: false,
  },
  {
    key: NESTED_HOST_LOCATIONS,
    initialValue: [],
    displayAs: NESTED_MULTI_SELECT,
    options: {},
    disableSearch: false,
    hasSelectAll: false,
  },
  {
    key: NESTED_LISTING_LOCATIONS,
    initialValue: [],
    displayAs: NESTED_MULTI_SELECT,
    options: {},
    disableSearch: false,
    hasSelectAll: false,
  },
]

const [
  zoneType,
  rentalsSearch,
  rentalsSearchFields,
  rentalsSortField,
  numberOfListings,
  hostId,
  joinedIn,
  hostType,
  nestedHostLocations,
  nestedListingLocations,
] = rentalsFilters

export const rentalsHostsFilters = [
  zoneType,
  rentalsSearch,
  rentalsSearchFields,
  rentalsSortField,
  numberOfListings,
  joinedIn,
  hostType,
  nestedHostLocations,
  nestedListingLocations,
]

export interface RentalsFilterGroups {
  primary: [RentalsZoneType, RentalsNumberOfListings]
  joined: RentalsJoinedIn[]
  type: RentalsHostType[]
  hostLocation: RentalsNestedLocations[]
  listingLocations: RentalsNestedLocations[]
}

export const hostsFilterGroups = {
  // primary: [zoneType, numberOfListings],
  // joined: [joinedIn],
  // type: [hostType],
  hostLocation: [nestedHostLocations],
  listingLocation: [nestedListingLocations],
}
