import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectOrganizationsState = state => state.organizations || initialState

export const selectDisplayedOrganization = createSelector(
  selectOrganizationsState,
  state => state.displayedOrganization,
)

export const selectDisplayedUser = createSelector(selectOrganizationsState, state => state.displayedUser)

export const selectDisplayedZone = createSelector(selectOrganizationsState, state => state.displayedZone)
