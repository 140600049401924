import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { fetchVerticalTableData } from 'services/verticalsTablesApi'
import { BaseEntityForTable } from 'utils/types'

interface UseVerticalEntitiesQuery {
  verticalIndices: string
  currentQueryKey: string
}

const PAGE_SIZE = 100

export const useVerticalEntitiesQuery = <T extends BaseEntityForTable>({
  verticalIndices,
  currentQueryKey,
}: UseVerticalEntitiesQuery) => {
  const {
    data: verticalEntities,
    fetchNextPage,
    isFetching,
    isLoading,
  } = useInfiniteQuery<T>({
    queryKey: [currentQueryKey, verticalIndices],
    queryFn: async ({ pageParam = 0 }) => {
      const fromIndex = pageParam * PAGE_SIZE
      return await fetchVerticalTableData(verticalIndices, fromIndex, PAGE_SIZE)
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const flatVerticalEntities: T[] | null = useMemo(() => {
    const flatEntities = verticalEntities?.pages.flatMap((page: T) => page) || null
    return flatEntities?.[0] ? flatEntities : null
  }, [verticalEntities])

  return {
    verticalEntities: flatVerticalEntities,
    fetchNextPage,
    isFetching,
    isLoading,
  }
}
