import { defineMessages } from 'react-intl'

const scope = 'app.components.SummarySection'

export default defineMessages({
  headers: {
    entities: {
      id: `${scope}.headers.entities`,
      defaultMessage: 'Entities',
    },
    income: {
      id: `${scope}.headers.income`,
      defaultMessage: 'Revenue',
    },
    industries: {
      id: `${scope}.headers.industries`,
      defaultMessage: 'Top Industries',
    },
    platforms: {
      id: `${scope}.headers.platforms`,
      defaultMessage: 'Top Platforms',
    },
    revenueSources: {
      id: `${scope}.headers.revenueSources`,
      defaultMessage: 'Top Revenue Sources',
    },
    entityOverview: {
      id: `${scope}.headers.entityOverview`,
      defaultMessage: 'Entity Overview',
    },
    documentedEntity: {
      id: `${scope}.headers.documentedEntity`,
      defaultMessage: 'Documented',
    },
    reportedEntity: {
      id: `${scope}.headers.reportedEntity`,
      defaultMessage: 'Reported',
    },
    taxGapEntity: {
      id: `${scope}.headers.taxGapEntity`,
      defaultMessage: 'Tax Gap',
    },
  },
})
