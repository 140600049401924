import React, { FC, useMemo } from 'react'
import cx from 'classnames'
import { FreelancersEntityForTable } from 'containers/FreelancersTablePage/types'
import VerticalTableBase from 'components/verticals/VerticalTableBase/VerticalTableBase'
import { VerticalTableCMPBaseProps } from 'components/verticals/VerticalTableBase/types'
import TableText from 'components/common/TableText'
import Income from 'components/Income'

import { formatNumber } from 'utils/formatters'

import { MRT_ColumnDef } from 'material-react-table'
import { TABLE_TEXT_TYPES } from 'utils/types'
import { useBaseCellStyles } from 'components/verticals/VerticalTableBase/useBaseCellStyles'
import { originalSourceToImageMap } from '../../BusinessSource/specs'
import styles from '../../../containers/VerticalPage/common/ActivityDropdown/ActivityDropdown.scss'
import freelancersTableStyles from './freelancersTable.scss'
import { RevenueSource, RevenueSourceSummary } from '../../../store/verticals/types'

const FreelancersTable: FC<VerticalTableCMPBaseProps<FreelancersEntityForTable>> = ({
  verticalEntities,
  fetchNextPage,
  isFetching,
  totalDBRowCount,
  totalFetched,
}) => {
  const baseCellStyles = useBaseCellStyles()
  const revenueStyles = {
    muiTableHeadCellProps: {
      sx: {
        minWidth: 130,
        maxWidth: 140,
        borderBottom: 'none',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        ...baseCellStyles,
        minWidth: 130,
        maxWidth: 130,
      },
    },
  }

  const getTotalRevenueSources = (revenueSources: RevenueSource[]) => {
    const counts: RevenueSourceSummary[] = []
    revenueSources.forEach(item => {
      const found = counts.find(c => c.platform === item.platform)
      if (found) {
        found.count++
      } else {
        counts.push({ platform: item.platform, count: 1 })
      }
    })
    return counts
  }

  const columns = useMemo<MRT_ColumnDef<FreelancersEntityForTable>[]>(
    () => [
      {
        header: 'Revenue',
        Header: <TableText textType={TABLE_TEXT_TYPES.groupHeader}>Revenue</TableText>,
        columns: [
          {
            id: 'documented revenue',
            header: 'Documented Revenue',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Documented Revenue</TableText>,
            accessorFn: item => (
              <TableText>
                {item?.totals?.documentedRevenue ? <Income value={item.totals.documentedRevenue} /> : 'N/A'}
              </TableText>
            ),
            ...revenueStyles,
          },
          {
            id: 'exact revenue',
            header: 'Estimated Revenue',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Exact Revenue</TableText>,
            accessorFn: item => (
              <TableText>
                {item?.totals?.exactRevenue ? <Income value={item?.totals?.exactRevenue} /> : 'N/A'}
              </TableText>
            ),
            ...revenueStyles,
          },
          {
            id: 'revenue sources',
            header: 'Revenue sources',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Revenue sources</TableText>,
            accessorFn: item => (
              <TableText>
                <div className={cx(freelancersTableStyles.platformIconsContainer)}>
                  {getTotalRevenueSources(item.revenueSources).map(x => (
                    <div className={freelancersTableStyles.tag}>
                      <img
                        src={originalSourceToImageMap[x?.platform?.toLocaleLowerCase() || 'globe']}
                        alt='Platform'
                        className={styles.platformIcon}
                      />
                      {x.platform} {x.count > 1 ? `(${x.count})` : ''}
                    </div>
                  ))}
                </div>
              </TableText>
            ),
            ...revenueStyles,
          },
        ],
      },
      {
        header: 'Activity Details',
        columns: [
          {
            id: 'total sold items',
            header: 'Total Sold Items',
            Header: <TableText textType={TABLE_TEXT_TYPES.columnHeader}>Total Sold Items</TableText>,
            muiTableBodyCellProps: {
              sx: { ...baseCellStyles },
            },
            accessorFn: item => (
              <TableText>
                {formatNumber(item?.totals.totalItemsSold, null, {
                  style: 'decimal',
                })}
              </TableText>
            ),
          },
        ],
      },
    ],
    [],
  )

  return (
    <VerticalTableBase<FreelancersEntityForTable>
      columns={columns}
      items={verticalEntities}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      totalFetched={totalFetched}
      totalDBRowCount={totalDBRowCount}
    />
  )
}

export default FreelancersTable
