import React from 'react'
import GenericCard from 'components/common/GenericCard'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CardContent from '@mui/material/CardContent'
import GenericCardHeader from 'components/common/GenericCardHeader'
import GenericHeading from 'components/common/GenericHeading'
import { useTheme } from '@mui/material/styles'

export const EntityCardLoader = () => (
  <GenericCard>
    <GenericCardHeader
      sx={{ bgcolor: 'secondary.main', pl: '24px', height: { lg: 40, xl: 46, xlplus: 66, xxxl: 96 } }}
      title={
        <Skeleton
          variant='text'
          sx={{
            fontSize: { lg: '1rem', xlplus: '1.4rem', xxxl: '2rem' },
            bgcolor: '#E9ECF1',
            width: { lg: 100, xlplus: 200, xxxl: 300 },
          }}
        />
      }
    />
    <CardContent sx={{ p: { lg: '16px 20px 20px', xxxl: '32px 40px 40px' } }}>
      <Stack direction='row' spacing={3}>
        <Skeleton
          variant='circular'
          sx={{
            width: { lg: 70, xxxl: 140 },
            height: { lg: 70, xxxl: 140 },
          }}
        />
        <Box width='80%'>
          <Skeleton variant='text' sx={{ fontSize: { lg: '1.4rem', xxxl: '2.4rem' } }} />
          <Skeleton variant='text' sx={{ fontSize: { lg: '1.4rem', xxxl: '2.4rem' }, width: '60%' }} />
          <Skeleton variant='text' sx={{ fontSize: { lg: '1.4rem', xxxl: '2.4rem' }, width: '40%' }} />
        </Box>
      </Stack>
    </CardContent>
  </GenericCard>
)

export const ActivityOverviewCardLoader = () => (
  <GenericCard sx={{ height: '99%' }}>
    <GenericCardHeader
      sx={{ bgcolor: 'secondary.main', height: { lg: 40, xl: 46, xlplus: 66, xxxl: 96 } }}
      title={
        <GenericHeading
          sx={{
            color: '#fff',
            fontSize: { lg: 20, xxxl: 36 },
            p: { lg: '16px' },
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          ACTIVITY OVERVIEW
        </GenericHeading>
      }
    />
    <CardContent sx={{ p: { lg: '22px', xxxl: '44px' }, display: 'flex', alignItems: 'center', flexGrow: 1 }}>
      <Stack direction='row' spacing={2} sx={{ justifyContent: 'space-between', p: '0', flexGrow: 1 }}>
        <Stack spacing={2}>
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '0.5rem', xxxl: '1.5rem' }, width: { lg: 50, xlpus: 100, xxxl: 150 } }}
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '1rem', xxxl: '2rem' }, width: { lg: 100, xlpus: 150, xxxl: 250 } }}
          />
        </Stack>
        <Stack spacing={2}>
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '0.5rem', xxxl: '1.5rem' }, width: { lg: 50, xlpus: 100, xxxl: 150 } }}
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '1rem', xxxl: '2rem' }, width: { lg: 100, xlpus: 150, xxxl: 250 } }}
          />
        </Stack>
        <Stack spacing={2}>
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '0.5rem', xxxl: '1.5rem' }, width: { lg: 50, xlpus: 100, xxxl: 150 } }}
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '1rem', xxxl: '2rem' }, width: { lg: 100, xlpus: 150, xxxl: 250 } }}
          />
        </Stack>
        <Stack spacing={2}>
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '0.5rem', xxxl: '1.5rem' }, width: { lg: 50, xlpus: 100, xxxl: 150 } }}
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '1rem', xxxl: '2rem' }, width: { lg: 100, xlpus: 150, xxxl: 250 } }}
          />
        </Stack>
        <Stack spacing={2}>
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '0.5rem', xxxl: '1.5rem' }, width: { lg: 50, xlpus: 100, xxxl: 150 } }}
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: { lg: '1rem', xxxl: '2rem' }, width: { lg: 100, xlpus: 150, xxxl: 250 } }}
          />
        </Stack>
      </Stack>
    </CardContent>
  </GenericCard>
)

export const VerticalCardLoader = () => {
  const theme = useTheme()
  return (
    <GenericCard sx={{ width: '32%', height: '19vh' }}>
      <GenericCardHeader
        sx={{
          pl: { lg: '24px', xxxl: '36px' },
          borderBlockEnd: '1px solid #DFDFDF',
          paddingBlock: { xlplus: '36px', xxxl: '52px' },
        }}
        title={
          <Skeleton
            variant='circular'
            sx={{
              width: { lg: 20, xl: 30, xlplus: 40, xxxl: 60 },
              height: { lg: 20, xl: 30, xlplus: 40, xxxl: 60 },
            }}
          />
        }
      />
      <CardContent
        sx={{
          p: '16px 24px 24px',
          [theme.breakpoints.down('xl')]: {
            p: '8px 12px 12px',
            '&:last-child': { pb: '10px' },
          },
          [theme.breakpoints.up('xlplus')]: {
            p: '32px 40px 40px',
            '&:last-child': { pb: '40px' },
          },
        }}
      >
        <Stack spacing={2} justifyContent='center' alignItems='center'>
          <Skeleton
            variant='circular'
            sx={{
              width: { lg: 50, xlplus: 90, xxxl: 120 },
              height: { lg: 50, xlplus: 90, xxxl: 120 },
            }}
          />
          <Skeleton
            variant='text'
            sx={{
              fontSize: { lg: '0.6rem', xlplus: '1.6rem', xxxl: '2.6rem' },
              width: 400,
            }}
          />
        </Stack>
      </CardContent>
    </GenericCard>
  )
}

export const VerticalCarouselLoader = () => (
  <Box sx={{ gridColumn: '1 / 3', gridRow: '2 / 3' }}>
    <Skeleton
      variant='text'
      sx={{ fontSize: { lg: '1rem', xxxl: '2rem' }, width: { lg: 300, xxxl: 600 }, mb: { lg: 2, xxxl: 6 } }}
    />
    <Stack direction='row' width='100%' justifyContent='space-between' alignItems='center'>
      <VerticalCardLoader />
      <VerticalCardLoader />
      <VerticalCardLoader />
    </Stack>
  </Box>
)

export const VerticalTabsHeadingLoader = () => (
  <Skeleton variant='text' sx={{ fontSize: '1.8rem', width: { lg: 300, xlplus: 600 } }} />
)

export const VerticalPieChartLoader = () => (
  <Stack direction='row' width='100%' justifyContent='space-between' alignItems='flex-start' alignSelf='center' pt={2}>
    <Stack width='40%'>
      <Skeleton variant='circular' sx={{ width: { lg: 150, xxxl: 400 }, height: { lg: 150, xxxl: 400 }, mb: 2 }} />
      <Skeleton variant='text' sx={{ width: { lg: 50, xxxl: 200 }, fontSize: { lg: '1rem', xxxl: '1.5rem' } }} />
      <Skeleton variant='text' sx={{ width: { lg: 50, xxxl: 200 }, fontSize: { lg: '1rem', xxxl: '1.5rem' } }} />
      <Skeleton variant='text' sx={{ width: { lg: 50, xxxl: 200 }, fontSize: { lg: '1rem', xxxl: '1.5rem' } }} />
    </Stack>
    <Stack width='40%'>
      <Skeleton variant='circular' sx={{ width: { lg: 150, xxxl: 400 }, height: { lg: 150, xxxl: 400 }, mb: 2 }} />
      <Skeleton variant='text' sx={{ width: { lg: 50, xxxl: 200 }, fontSize: { lg: '1rem', xxxl: '1.5rem' } }} />
      <Skeleton variant='text' sx={{ width: { lg: 50, xxxl: 200 }, fontSize: { lg: '1rem', xxxl: '1.5rem' } }} />
      <Skeleton variant='text' sx={{ width: { lg: 50, xxxl: 200 }, fontSize: { lg: '1rem', xxxl: '1.5rem' } }} />
    </Stack>
  </Stack>
)

export const VerticalBarChartLoader = () => (
  <Stack width='100%' justifyContent='center' alignItems='center' alignSelf='center' pt={6} spacing={2}>
    <Stack direction='row' justifyContent='flex-end' alignItems='flex-end' width='40%' spacing={4}>
      <Skeleton variant='rectangular' sx={{ width: { lg: 30, xxxl: 90 }, height: { lg: 10, xxxl: 100 } }} />
      <Skeleton variant='rectangular' sx={{ width: { lg: 30, xxxl: 90 }, height: { lg: 25, xxxl: 190 } }} />
      <Skeleton variant='rectangular' sx={{ width: { lg: 30, xxxl: 90 }, height: { lg: 50, xxxl: 240 } }} />
      <Skeleton variant='rectangular' sx={{ width: { lg: 30, xxxl: 90 }, height: { lg: 100, xxxl: 290 } }} />
      <Skeleton variant='rectangular' sx={{ width: { lg: 30, xxxl: 90 }, height: { lg: 150, xxxl: 340 } }} />
    </Stack>
    <Stack width='80%'>
      <Skeleton variant='text' sx={{ fontSize: { lg: '0.5rem', xxxl: '2rem' }, mb: { lg: 2, xlplus: 4 } }} />
      <Skeleton variant='text' sx={{ fontSize: { lg: '0.5rem', xxxl: '2rem' }, mb: { lg: 2, xlplus: 4 } }} />
      <Skeleton variant='text' sx={{ fontSize: { lg: '0.5rem', xxxl: '2rem' }, mb: { lg: 2, xlplus: 4 } }} />
      <Skeleton variant='text' sx={{ fontSize: { lg: '0.5rem', xxxl: '2rem' }, mb: { lg: 2, xlplus: 4 } }} />
    </Stack>
  </Stack>
)

export const VerticalGraphLoader = () => (
  <Stack sx={{ flex: 1, bgcolor: 'white', padding: '24px', borderRadius: '16px' }}>
    <Skeleton variant='rounded' sx={{ width: '100%', height: '100%', flexGrow: 1 }} />
  </Stack>
)

export const VerticalBusinessActivityLoader = () => (
  <Stack flex={1} spacing={2} mt={1} mr='24px' maxHeight='100%' minHeight='100%' height='100%'>
    <Skeleton variant='rounded' sx={{ width: '100%', fontSize: '0.5rem', flexGrow: 1 }} />
    <Skeleton variant='rounded' sx={{ width: '100%', fontSize: '1rem', flexGrow: 1 }} />
    <Skeleton variant='rounded' sx={{ width: '100%', fontSize: '1rem', flexGrow: 1 }} />
  </Stack>
)
