import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from 'services/api'
import { setGlobalLoading, addAlert, DUMMY_ZONE_NAME } from 'store/global'
import { defaultEntitiesDisplayedColumns } from 'containers/NFTMapper/specs'
import { nftMapperFilters } from 'utils/filters/nftMapper'

const SLICE_KEY = 'nftMapper'

export const fetchNFTEntities = createAsyncThunk(
  `${SLICE_KEY}/fetchNFTEntities`,
  async ({ from = 0, filters = [] }, { getState, dispatch }) => {
    try {
      const { currentZone } = getState().global
      if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

      if (from === 0) {
        dispatch(setIsLoadingSummary({ isLoading: true }))
      }

      const { entities, total, summary } = await apiService.nftEntities(currentZone, from, filters)
      const allowScrolling = entities.length > 0

      dispatch(setGlobalLoading(false))

      return {
        isScrolling: from > 0,
        allowScrolling,
        entities,
        total,
        summary,
      }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching entities from API',
        }),
      )
    }
  },
)

export const fetchFilterOptions = createAsyncThunk(
  `${SLICE_KEY}/fetchFilterOptions`,
  async ({ filters = [] }, { getState, dispatch }) => {
    try {
      const { currentZone } = getState().global
      if (!currentZone) return {}

      const filterOptions = await apiService.nftEntitiesFilterOptions(currentZone, filters)

      return { filterOptions }
    } catch (error) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching filter options from API',
        }),
      )
    }
  },
)

export const initialState = {
  isLoadingSummary: false,
  totalNFTEntities: 0,
  summary: {
    entityCounts: {},
    incomeCounts: {},
    topIndustries: [],
  },
  entities: [],
  from: 0,
  allowScrolling: true,
  isLoadingInitial: false,
  isLoadingNextBatch: false,
  nextBatchStartingIndex: 0,
  entitiesDisplayedColumns: defaultEntitiesDisplayedColumns,
  filters: nftMapperFilters.reduce((acc, { key, initialValue }) => ({ ...acc, [key]: initialValue }), {}),
  filterOptions: {},
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setIsLoadingInitial(state, { payload = {} }) {
      state.isLoadingInitial = payload.isLoading || false
    },
    setIsLoadingSummary(state, { payload = {} }) {
      state.isLoadingSummary = payload.isLoading || false
    },
    setIsLoadingNextBatch(state, { payload = {} }) {
      state.isLoadingNextBatch = payload.isLoading
    },
    setNextBatchStartingIndex(state, { payload }) {
      const { index } = payload
      state.nextBatchStartingIndex = index
    },
    setEntitiesDisplayedColumns(state, { payload }) {
      const { columns = [] } = payload
      state.entitiesDisplayedColumns = columns
    },
    applyFilters(state, { payload = {} }) {
      const { filters } = payload
      state.filters = filters
    },
    setFilterOptions(state, { payload = {} }) {
      const { filterOptions } = payload
      state.filterOptions = filterOptions
    },
    setFilters(state, { payload = {} }) {
      const { filters } = payload
      state.filters = filters
    },
  },
  extraReducers: {
    [fetchNFTEntities.fulfilled]: (state, { payload = {} }) => {
      if (Object.keys(payload).length === 0) return
      const { isScrolling, entities, total, allowScrolling, summary } = payload

      state.entities = isScrolling ? [...state.entities, ...entities] : entities

      state.totalNFTEntities = total
      state.summary = summary
      state.isLoadingSummary = false
      state.allowScrolling = allowScrolling
      state.isLoadingInitial = false
      state.isLoadingNextBatch = false
      state.from = state.entities.length
    },
    [fetchFilterOptions.fulfilled]: (state, { payload = {} }) => {
      if (Object.keys(payload).length === 0) return
      const { filterOptions } = payload
      state.filterOptions = filterOptions
    },
  },
})

export const {
  setIsLoadingSummary,
  setIsLoadingInitial,
  setIsLoadingNextBatch,
  setNextBatchStartingIndex,
  setEntitiesDisplayedColumns,
  applyFilters,
} = slice.actions

export const NFTMapperReducer = slice.reducer
export default slice
