import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import Button from 'components/Button'
import Icon from 'components/Icon'
import messages from './messages'
import styles from './MapHeader.scss'

const MapHeader = ({ type, isExpanded, onToggleExpand, onClose, header, buttonColor = 'primary' }) => (
  <div className={styles.container}>
    <h3 className={cx(styles.header, { [styles.graph]: type === 'graph' })}>
      {header || <FormattedMessage {...messages.header} values={{ type }} />}
    </h3>
    {onToggleExpand && (
      <Button
        color={buttonColor}
        outline
        onClick={onToggleExpand}
        className={cx(styles.expand, {
          [styles.expanded]: isExpanded,
          [styles.graph]: type === 'graph',
        })}
      >
        <Icon name='expand' size='regular'>
          <FormattedMessage {...messages.expand} />
        </Icon>
      </Button>
    )}
    {onClose && type !== 'graph' && (
      <Button color={buttonColor} onClick={onClose} className={styles.close}>
        <Icon name='close' size='regular' />
      </Button>
    )}
    {type === 'graph' && onClose && (
      <Button
        color={buttonColor}
        outline
        onClick={onClose}
        className={cx(styles.collapse, {
          [styles.expanded]: isExpanded,
          [styles.graph]: type === 'graph',
        })}
      >
        <Icon name='collapse' size='regular'>
          <FormattedMessage {...messages.collapse} />
        </Icon>
      </Button>
    )}
  </div>
)

MapHeader.propTypes = {
  type: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  onToggleExpand: PropTypes.func,
  onClose: PropTypes.func,
}

MapHeader.defaultProps = {
  isExpanded: false,
}

export default MapHeader
