import React, { FC } from 'react'
import Income from 'components/Income'
import Stack from '@mui/material/Stack'
import GenericText from 'components/common/GenericText'

interface VerticalBARevenueProps {
  revenue: number | null
  revenueMax?: number | null
}

const VerticalBARevenueRange: FC<VerticalBARevenueProps> = ({ revenue, revenueMax}) => (
  <Stack sx={{ pt: { lg: '8px', xxxl: '40px' } }}>
    <GenericText sx={{ fontSize: { xs: 12, xl: 14, xxl: 16, xxxl: 32 }, color: '#7F8897' }}>Price</GenericText>
    <GenericText sx={{ fontSize: { xs: 18, xl: 22, xxl: 24, xxxl: 46 }, fontFamily: 'Dosis', letterSpacing: '0.4px' }}>
      {revenue?.toString() ? <Income value={revenue} /> : 'N/A'} {revenueMax ? <> - <Income value={revenueMax || 0} /></> : ''}
    </GenericText>
  </Stack>
)

export default VerticalBARevenueRange
