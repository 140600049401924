import React, { useMemo } from 'react'
import { formatNumber } from 'utils/formatters'
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { useTheme } from '@mui/material/styles'
import { PieChartData } from 'utils/types'
import styles from './Common.scss'

const RADIAN = Math.PI / 180

interface RenderCustomizedLabel {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  value: number
}

interface GenericPieChartProps {
  chartData: PieChartData[]
  isAnimationActive?: boolean
}

const GenericPieChart: React.FC<GenericPieChartProps> = ({ chartData, isAnimationActive = false }) => {
  const theme = useTheme()

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }: RenderCustomizedLabel) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const formattedValue = formatNumber(value)
    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor='middle'
        dominantBaseline='central'
        fontFamily='Source Sans Pro'
        className={styles.textOnChart}
      >
        {formattedValue === 'N/A' ? '' : formattedValue}
      </text>
    )
  }

  const totalValue = useMemo(() => chartData.reduce((acc, cur) => acc + cur.value, 0), [chartData])

  return (
    <ResponsiveContainer width='100%' aspect={1}>
      <PieChart>
        <Pie
          data={chartData}
          cx='50%'
          cy='50%'
          labelLine={false}
          minAngle={40}
          label={renderCustomizedLabel}
          outerRadius='100%'
          innerRadius='40%'
          dataKey='value'
          isAnimationActive={isAnimationActive}
        >
          <Label
            value={formatNumber(totalValue)}
            position='center'
            fill='#000'
            fontWeight='600'
            className={styles.labelOnChart}
          />
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={theme.palette.chartsTheme[entry.name?.toLowerCase()]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default GenericPieChart
