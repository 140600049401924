import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './Loader.scss'

const allowedTypes = ['globalLoading', 'scrolling']
const [TYPE_GLOBAL_LOADING, TYPE_SCROLLING] = allowedTypes

const cubes = Array(9).fill(0)
const bounces = Array(3).fill(0)

const Loader = ({ type, message, className }) => (
  <div className={cx(styles.container, styles[type], className)}>
    {type === TYPE_SCROLLING && (
      <div className={styles.spinner}>
        {bounces.map((b, index) => (
          <div key={index} className={cx(styles.bounce, styles[`bounce-${index}`])} />
        ))}
      </div>
    )}
    {type === TYPE_GLOBAL_LOADING && (
      <div className={styles.cubeGrid}>
        {cubes.map((c, index) => (
          <div key={index} className={cx(styles.cube, styles[`cube-${index}`])} />
        ))}
      </div>
    )}
    <div className={styles.message}>{message}</div>
  </div>
)

Loader.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
}

Loader.defaultProps = {
  type: TYPE_SCROLLING,
}

export default Loader
