import axios from 'services/axios'
import apiService from './api'

export const fetchVerticalTableData = async (zoneEndpoint: string, from: number = 0, size: number = 100) => {
  const endpoint = 'verticalsTable'
  if (!zoneEndpoint) return

  const params = {
    from,
    size,
    zoneEndpoint,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
    return null
  }
}

export const fetchVerticalTableOverviewData = async (zoneEndpoint: string) => {
  const endpoint = 'verticalsTable/overview'
  if (!zoneEndpoint) return

  const params = {
    zoneEndpoint,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
    return null
  }
}

export const exportEntitiesCSV = async (zoneEndpoint: string, _source: { includes: string[] }, type?: string) => {
  const endpoint = 'verticalsTable/exportEntitiesCSV'
  if (!zoneEndpoint) return

  const body = {
    zoneEndpoint,
    _source,
    type,
  }

  try {
    const { data } = await axios.post(endpoint, body)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
    return null
  }
}
