import React from 'react'

const ZoomPlus = () => (
  <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.18394 0.516998C12.0717 0.516998 15.1771 3.49905 15.1771 7.12393C15.1771 10.7488 12.0717 13.7309 8.18394 13.7309C4.29609 13.7309 1.1897 10.7487 1.1897 7.12393C1.1897 3.49915 4.29609 0.516998 8.18394 0.516998Z'
      fill='#EFF2F6'
      stroke='#4682FF'
    />
    <path
      d='M4.43604 7.12264L11.9297 7.12264'
      stroke='#4682FF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.18281 3.57028L8.18281 10.6772'
      stroke='#4682FF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ZoomPlus
