import React, { FC } from 'react'
import cx from 'classnames'
import MonthPicker from 'components/MonthPicker/MonthPicker'
import { PeriodProps } from './utils'
import styles from './DatePicker.scss'

interface DatePickerProps {
  periodStart: string
  periodEnd: string
  format?: string
  handlePeriodChange: (period: PeriodProps) => void
  popperPlacement?: string
  isCharts?: boolean
  iconUrl?: string
  containerClassName?: string
  iconClassName?: string
  showYearPicker?: boolean
}

const DatePicker: FC<DatePickerProps> = ({
  periodStart,
  periodEnd,
  handlePeriodChange,
  format = 'MMMM yyyy',
  popperPlacement,
  isCharts,
  iconUrl,
  containerClassName,
  iconClassName,
  showYearPicker,
}) => (
  <MonthPicker
    start={periodStart}
    end={periodEnd}
    isNFT
    format={format}
    onChange={handlePeriodChange}
    popperPlacement={popperPlacement}
    isCharts={isCharts}
    iconUrl={iconUrl}
    containerClassName={cx(styles.container, containerClassName)}
    iconClassName={cx(styles.icon, iconClassName)}
    showYearPicker={showYearPicker}
  />
)

export default DatePicker
