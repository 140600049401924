import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from 'services/api'
import { setGlobalLoading } from 'store/global'

const SLICE_KEY = 'businessRequests'

export const fetchBusinessRequests = createAsyncThunk(
  `${SLICE_KEY}/fetchBusinessRequests`,
  async (args, { getState, dispatch }) => {
    dispatch(setIsLoading(true))
    const { businessRequests, organizationName } = await apiService.businessRequests()

    dispatch(setIsLoading(false))

    let currentOrganization = organizationName
    if (!currentOrganization) {
      currentOrganization = getState().global
    }

    return {
      currentOrganization,
      businessRequests,
    }
  },
)

export const submitBusinessRequest = createAsyncThunk(
  `${SLICE_KEY}/submitBusinessRequest`,
  async ({ businessRequest }, { dispatch }) => {
    dispatch(setGlobalLoading(true))

    const data = await apiService.submitBusinessRequest(businessRequest)

    dispatch(setGlobalLoading(false))

    dispatch(fetchBusinessRequests())

    return data
  },
)

export const deleteBusinessRequest = createAsyncThunk(
  `${SLICE_KEY}/deleteBusinessRequest`,
  async ({ businessRequest }, { dispatch }) => {
    dispatch(setGlobalLoading(true))

    const { organizationName, id } = businessRequest
    const data = await apiService.deleteBusinessRequest(organizationName, id)

    dispatch(setGlobalLoading(false))

    dispatch(fetchBusinessRequests())

    return data
  },
)

export const initialState = {
  isLoading: false,
  businessRequests: {},
  currentRequest: {
    isOpen: false,
  },
  isRequestCreated: false,
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setIsLoading(state, { payload: isLoading = false }) {
      state.isLoading = isLoading
    },
    setCurrentRequest(state, { payload = {} }) {
      state.currentRequest = payload.currentRequest || { isOpen: false }
    },
    updateCurrentRequest(state, { payload = {} }) {
      const { key, value } = payload

      state.currentRequest = {
        ...state.currentRequest,
        [key]: value,
      }
    },
    closeCurrentRequestModal(state) {
      state.currentRequest = {
        ...state.currentRequest,
        isOpen: false,
      }
    },
  },
  extraReducers: {
    [fetchBusinessRequests.fulfilled]: (state, { payload = {} }) => {
      const { businessRequests, currentOrganization } = payload

      if (Array.isArray(businessRequests)) {
        state.businessRequests = {
          [currentOrganization]: businessRequests,
        }

        return
      }

      state.businessRequests = businessRequests
    },
    [submitBusinessRequest.fulfilled]: (state, { payload = {} }) => {
      const { isCreated } = payload
      state.isRequestCreated = isCreated
    },
  },
})

export const { setIsLoading, setCurrentRequest, updateCurrentRequest, closeCurrentRequestModal } = slice.actions

export const businessRequestsReducer = slice.reducer
export default slice
