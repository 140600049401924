import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Map from 'components/Map'
import MapHeader from 'components/MapHeader'

import { SECTION_HOST } from 'containers/Rentals/specs'

import { selectMapBaseUrl, selectMapZoomDelta, selectPlatform } from 'store/global'
import { selectHostsFilters, selectPointsClusters, fetchMapClusterAggregation } from 'store/rentals'
import styles from './RentalsAnalyticsMap.scss'
import { selectCurrentZone } from 'store/global'
import usePrevious from '@rooks/use-previous'

export default function RentalsAnalyticsMap(props) {
  const { listType, header, clusterMode, clickOnMarker, showHeader } = props

  const dispatch = useDispatch()

  const mapBaseUrl = useSelector(selectMapBaseUrl)
  const mapZoomDelta = useSelector(selectMapZoomDelta)
  const hostFilters = useSelector(selectHostsFilters)
  const pointsClusters = useSelector(selectPointsClusters)
  const platform = useSelector(selectPlatform)
  const currentZone = useSelector(selectCurrentZone)
  const previousZone = usePrevious(currentZone)

  const [currentBounds, setCurrentBounds] = useState({
    top_left: { lng: -180, lat: 90 },
    bottom_right: { lng: 180, lat: -90 },
    precision: 1,
  })
  // const [currentBounds, setCurrentBounds] = useState({
  //   top_left: { lng: 0, lat: 0 },
  //   bottom_right: { lng: 0, lat: 0 },
  //   precision: 1
  // });
  const [listingsLocations, setListingLocations] = useState([])

  // useEffect(() => {
  //   if ((selectedHost.enrichedListings || hosts.length === 0) || clusterMode) return;
  //   if (listingsLocations.length !== 0) return; // TODO REMOVE ME
  //   setListingLocations(
  //     hosts
  //       .map(host =>
  //         host.enrichedListings.map(listing => ({ ...listing, hostName: host.hostName, hostSiteId: host.hostSiteId }))
  //       )
  //       .flat()
  //   );
  // }, [hosts]);

  useEffect(() => {
    if (!pointsClusters || previousZone !== currentZone) {
      dispatch(
        fetchMapClusterAggregation({
          top_left: { lng: -180, lat: 90 },
          bottom_right: { lng: 180, lat: -90 },
          precision: 2,
          hostFilters,
        }),
      )
      return
    }
    setListingLocations(pointsClusters)
  }, [pointsClusters, currentZone])

  const onZoom = async ({ top_left, bottom_right, precision }) => {
    if (top_left.lng === bottom_right.lng) return
    setCurrentBounds({ top_left, bottom_right, precision })
    if (clusterMode) {
      await dispatch(
        fetchMapClusterAggregation({
          top_left,
          bottom_right,
          precision,
          hostFilters,
        }),
      )
    }
  }

  useEffect(() => {
    onZoom(currentBounds)
  }, [hostFilters])

  return (
    <div className={styles.container}>
      {showHeader && <MapHeader type={listType} header={header} {...props} />}
      <div className={styles.mapWrapper}>
        <Map
          platform={platform}
          widgets={['zoomLevel']}
          baseUrl={mapBaseUrl}
          zoomDelta={mapZoomDelta}
          locations={[
            {
              list: listingsLocations,
              listColor: styles.listingLocations,
            },
          ]}
          onZoom={onZoom}
          pointsMode={false}
          clickOnMarker={clickOnMarker}
        />
      </div>
    </div>
  )
}

RentalsAnalyticsMap.propTypes = {
  listType: PropTypes.string,
  header: PropTypes.string,
  hosts: PropTypes.array,
  clusterMode: PropTypes.bool,
  clickOnMarker: PropTypes.func,
  showHeader: PropTypes.bool,
}

RentalsAnalyticsMap.defaultProps = {
  listType: SECTION_HOST,
  clusterMode: true,
  clickOnMarker: () => {},
  showHeader: true,
}
