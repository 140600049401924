import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { fetchVerticalDocumentActivity } from 'services/verticalsApi'
import { VerticalDocumentActivity } from 'store/verticals/types'
import { InfluencersFilters } from 'store/verticalsDetails/types'
import { Period } from 'utils/types'

interface UseDocumentActivitiesQueryProps {
  entityId: string
  detailsFilters: InfluencersFilters
  zoneActivityIndices: string | null
  currentPeriod?: Period
  pageSize: number
  activityType?: string | string[]
  sort?: string | qs.ParsedQs | string[] | qs.ParsedQs[]
}

export const useDocumentActivitiesQuery = ({
  entityId,
  detailsFilters,
  zoneActivityIndices,
  currentPeriod,
  pageSize,
  activityType,
  sort,
}: UseDocumentActivitiesQueryProps) => {
  const {
    data: verticalActivities,
    fetchNextPage,
    isFetching,
    isLoading,
  } = useInfiniteQuery<VerticalDocumentActivity>({
    queryKey: [entityId, detailsFilters, zoneActivityIndices, currentPeriod, pageSize, activityType, sort],
    queryFn: async ({ pageParam = 0 }) => {
      if (!zoneActivityIndices) return null
      const fromIndex = pageParam * pageSize
      return await fetchVerticalDocumentActivity(
        entityId,
        detailsFilters,
        fromIndex,
        zoneActivityIndices,
        currentPeriod,
        pageSize,
        activityType,
        sort,
      )
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const flatVerticalActivities: VerticalDocumentActivity[] | null = useMemo(() => {
    const flatEntities = verticalActivities?.pages.flatMap((page: VerticalDocumentActivity) => page) || null
    return flatEntities?.[0] ? flatEntities : null
  }, [verticalActivities])

  return {
    verticalDocumentActivity: flatVerticalActivities,
    fetchNextPage,
    isFetching,
    isLoading,
  }
}
