import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Position, Tooltip as Tippy } from 'react-tippy'

const allowedPositions = ['bottom', 'top', 'left', 'right']
const [POSITION_TOP] = allowedPositions

const Tooltip = ({ position, title, children }: { position?: Position; title: string; children: ReactNode;}) => (
  <Tippy position={position} title={title} arrow={true}>
    {children}
  </Tippy>
)

Tooltip.propTypes = {
  position: PropTypes.oneOf(allowedPositions),
  title: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
}

Tooltip.defaultProps = {
  position: POSITION_TOP,
}

export default Tooltip
