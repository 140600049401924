import { defineMessages } from 'react-intl'

const scope = 'app.containers.LoginPage'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Login Page',
  },
  logIn: {
    id: `${scope}.logIn`,
    defaultMessage: 'Log In to Ivix',
  },
  loginSuccess: {
    id: `${scope}.loginSuccess`,
    defaultMessage: 'You are now logged in',
  },
  loginFailed: {
    id: `${scope}.loginFailed`,
    defaultMessage: 'Login Failed: Incorrect username or password',
  },
  mfaFailed: {
    id: `${scope}.mfaFailed`,
    defaultMessage: 'Multi-factor authentication failed',
  },
  userIsBlocked: {
    id: `${scope}.userIsBLocked`,
    defaultMessage: 'Too many login attempts, please try again later',
  },
})
