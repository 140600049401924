import React, { FC } from 'react'
import { selectCurrentZone } from '../../store/global'
import NavigationHeader from 'components/NavigationHeader/NavigationHeader'
import BusinessesSummary from 'components/BusinessesSummary/BusinessesSummary'
import GridLoader from 'components/GridLoader/GridLoader'
import FreelancersTable from 'components/freelancers/FreelancersTable/FreelancersTable'
import TableActionsBars from 'components/verticals/TableActionsBar/TableActionsBar'

import { BaseTableOverview, CurrentZone } from 'utils/types'
import { FreelancersEntityForTable } from 'containers/FreelancersTablePage/types'

import { useVerticalOverviewQuery } from 'hooks/useVerticalOverviewQuery'
import { useVerticalEntitiesQuery } from 'hooks/useVerticalEntitiesQuery'

import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import messages from './messages'

import styles from './FreelancersTablePage.scss'
import {getUrlPageValues} from "../VerticalPage/common/utils";

const csvFileName = 'freelancers'

const FreelancersTablePage: FC = () => {
  const currentZone: CurrentZone = useSelector(selectCurrentZone)

  // Fetch overview data
  const { data: verticalOverviewData, isLoading: isLoadingOverview } = useVerticalOverviewQuery<BaseTableOverview>({
    verticalIndices: currentZone?.gigIndices,
    currentQueryKey: 'gig-overview-key',
  })

  // Fetch entities data
  const { verticalEntities, fetchNextPage, isFetching, isLoading } = useVerticalEntitiesQuery<FreelancersEntityForTable>({
    verticalIndices: currentZone?.gigIndices,
    currentQueryKey: 'gig-entities-key',
  })

  return (
    <div className={styles.container}>
      <nav className={styles.navigationHeaderWrapper}>
        <NavigationHeader />
      </nav>
      <header className='page-header withNavigationHeader'>
        <h1 className={cx('page-title', 'h1')}>
          <FormattedMessage {...messages.header} />
        </h1>
      </header>
      <main className={styles.main}>
        <div className={styles.contentWrapper}>
          <section className={styles.summary}>
            <BusinessesSummary
              isLoading={isLoadingOverview}
              summary={{
                entityCounts: verticalOverviewData?.summary.entitiesCount,
                incomeCounts: verticalOverviewData?.summary.incomeCounts,
                topIndustries: verticalOverviewData?.summary.platforms.map(({ source, count }) => [source, count]),
                industriesCount: verticalOverviewData?.summary.platforms.reduce((acc, { count }) => acc + count, 0),
              }}
              isInfluencers
            />
          </section>
          <div className={cx(styles.content, { [styles.withFilters]: false })}>
            <div className={styles.gridContainer}>
              <TableActionsBars
                countMessages={messages.count}
                displayedCount={verticalEntities ? verticalEntities.length : null}
                totalCount={verticalOverviewData ? verticalOverviewData.total : null}
                zoneEndpoint={currentZone?.gigIndices}
                csvFileName={csvFileName}
                type={getUrlPageValues()[0]}
              />
              {isLoading ? (
                <GridLoader />
              ) : (
                <section className={styles.grid}>
                  <FreelancersTable
                    isFetching={isFetching}
                    fetchNextPage={fetchNextPage}
                    totalFetched={verticalEntities?.length || 0}
                    totalDBRowCount={verticalOverviewData?.total || 0}
                    verticalEntities={verticalEntities}
                  />
                </section>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default FreelancersTablePage
