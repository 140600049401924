import React, { FC, useState } from 'react'
import ZoomPlus from 'components/common/icons/ZoomPlus'
import ZoomMinus from 'components/common/icons/ZoomMinus'
import styles from './ZoomGraph.scss'

interface ZoomGraphProps {
  onZoom: (zoomValue: number) => void
}

const ZoomGraph: FC<ZoomGraphProps> = ({ onZoom }) => {
  const [zoomValue, setZoomValue] = useState<number>(100)

  return (
    <div className={styles.container}>
      <div className={styles.zoomButtonsWrapper}>
        <button
          disabled={zoomValue >= 180}
          type='button'
          onClick={() => {
            onZoom(1.5)
            setZoomValue(prev => prev + 20)
          }}
        >
          <ZoomPlus />
        </button>
        <button
          disabled={zoomValue <= 20}
          type='button'
          onClick={() => {
            onZoom(1 / 1.5)
            setZoomValue(prev => prev - 20)
          }}
        >
          <ZoomMinus />
        </button>
      </div>
      <div className={styles.zoomValue}>{`${zoomValue}%`}</div>
    </div>
  )
}

export default ZoomGraph
