import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiService from 'services/api'

const SLICE_KEY = 'dashboard'

const TRANSMISSION_DURATION_DEFAULT = 7000

export const fetchData = createAsyncThunk(`${SLICE_KEY}/fetchData`, async (args, { getState }) => {
  const {
    currentZone: { businessMapperIndices },
  } = getState().global
  const data = await apiService.dashboard(businessMapperIndices)
  return data
})

const addTransmission = createAsyncThunk(`${SLICE_KEY}/addTransmission`, async (transmission, { dispatch }) => {
  const uuid = `${transmission.key}-${Date.now()}`

  const addedTransmission = {
    uuid,
    ...transmission,
  }

  dispatch(createTransmission({ transmission: addedTransmission }))

  setTimeout(() => {
    dispatch(removeTransmission({ uuid }))
  }, TRANSMISSION_DURATION_DEFAULT)
})

export const startTransmissions = createAsyncThunk(
  `${SLICE_KEY}/startTransmissions`,
  async (allTransmissions, { dispatch }) => {
    dispatch(resetTransmissions())

    allTransmissions.forEach(transmission => {
      const interval = setInterval(() => dispatch(addTransmission(transmission)), transmission.every)

      dispatch(addInterval({ interval }))
    })
  },
)

export const initialState = {
  totalBusinesses: 0,
  totals: {},
  entities: {},
  resolvers: [],
  transmissions: {},
  intervals: [],
}

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    createTransmission(state, { payload = {} }) {
      const { transmission } = payload
      const { uuid } = transmission

      state.transmissions[uuid] = transmission
    },
    addInterval(state, { payload = {} }) {
      const { interval } = payload

      state.intervals = [...state.intervals, interval]
    },
    removeTransmission(state, { payload = {} }) {
      const { uuid } = payload
      delete state.transmissions[uuid]
    },
    resetTransmissions(state) {
      state.transmissions = {}
      state.intervals.forEach(interval => clearInterval(interval))
    },
  },
  extraReducers: {
    [fetchData.fulfilled]: (state, { payload = {} }) => {
      const { totalBusinesses, totals = {}, entities = {}, resolvers } = payload

      state.totalBusinesses = totalBusinesses
      state.totals = totals
      state.entities = entities
      state.resolvers = resolvers
    },
  },
})

export const { createTransmission, addInterval, removeTransmission, resetTransmissions } = slice.actions

export const dashboardReducer = slice.reducer
