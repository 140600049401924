import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useBaseColumns } from 'components/verticals/VerticalTableBase/useBaseColumns'
import Loader from 'components/Loader/Loader'

import { BaseEntityForTable } from 'utils/types'
import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { TABLE_BOTTOM } from './config'
import { useBaseTableOptions } from './useBaseTableOptions'

interface VerticalTableBaseProps<VerticalEntityType extends BaseEntityForTable> {
  columns: MRT_ColumnDef<VerticalEntityType>[]
  items: VerticalEntityType[] | null
  totalFetched: number
  isFetching: boolean
  totalDBRowCount: number
  verticalTooltipValues?: Record<string, string>
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<VerticalEntityType, unknown>>
}

const VerticalTableBase = <T extends BaseEntityForTable>({
  columns,
  items,
  isFetching,
  totalFetched,
  totalDBRowCount,
  verticalTooltipValues,
  fetchNextPage,
}: VerticalTableBaseProps<T>) => {
  const baseColumns = useBaseColumns<T>({ verticalTooltipValues })
  const tableContainerRef = useRef<HTMLDivElement>(null)

  // Called on scroll and possibly on mount to fetch more data as
  // the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        // Once the user has scrolled within 100px of the bottom of the table,
        // fetch more data if we can
        const didUserReachBottom = scrollHeight - scrollTop - clientHeight < TABLE_BOTTOM
        const areThereMoreRowsToFetch = totalFetched < totalDBRowCount
        if (didUserReachBottom && !isFetching && areThereMoreRowsToFetch) {
          fetchNextPage()
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  )

  // A check on mount to see if the table is already scrolled
  // to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  // Merge base columns with vertical columns by group header
  const mergedColumns = useMemo(() => {
    const finalColumns = [...baseColumns]
    columns.forEach(verticalColumn => {
      const columnGroupIndex = finalColumns.findIndex(columnGroup => columnGroup.header === verticalColumn.header)
      if (columnGroupIndex > -1) {
        finalColumns[columnGroupIndex].columns!.push(...verticalColumn.columns!)
      }
    })
    return finalColumns
  }, [columns, baseColumns])

  const tableOptions = useBaseTableOptions({
    columns: mergedColumns,
    items,
    isFetching,
    totalFetched,
    totalDBRowCount,
    fetchNextPage,
  })

  const table = useMaterialReactTable(tableOptions)

  return (
    <Stack direction='column' sx={{ position: 'relative' }}>
      <MaterialReactTable table={table} />
      {isFetching && (
        <Box sx={{ position: 'absolute', bottom: '20px', left: '50%' }}>
          <Loader type='scrolling' />
        </Box>
      )}
    </Stack>
  )
}

export default VerticalTableBase
