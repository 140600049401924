import React, { FC } from 'react'
import Tabs, { TabsClasses } from '@mui/material/Tabs'
import Tab, { TabClasses } from '@mui/material/Tab'
import cx from 'classnames'
import { TabProp } from './utils'
import styles from './Tabs.scss'

interface CustomTabsProps {
  tabs: TabProp[]
  value: number
  disabledIds?: number[]
  tabsClasses?: Partial<TabsClasses>
  tabClasses?: Partial<TabClasses>
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void
}

const CustomTabs: FC<CustomTabsProps> = ({
  tabs,
  value,
  disabledIds = [],
  tabsClasses = {},
  tabClasses = {},
  onChange,
}) => (
  <Tabs
    className={styles.container}
    classes={{
      root: cx(styles.root, tabsClasses.root),
      flexContainer: cx(styles.flexContainer, tabsClasses.flexContainer),
      indicator: cx(styles.indicator, tabsClasses.indicator),
    }}
    value={value}
    onChange={onChange}
  >
    {tabs.map(tab => (
      <Tab
        key={tab.id}
        value={tab.id}
        classes={{
          root: cx(styles.tabRoot, tabClasses.root),
          selected: cx(styles.tabSelected, tabClasses.selected),
          disabled: cx(styles.tabDisabled, tabClasses.disabled),
        }}
        label={tab.label}
        disabled={disabledIds?.includes(tab.id)}
      />
    ))}
  </Tabs>
)

export default CustomTabs
