import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from 'services/api'
import localStorageService from 'services/storage'
import { addAlert, DUMMY_ZONE_NAME, setGlobalLoading } from 'store/global'
import { defaultDisplayedColumns } from 'containers/Businesses/specs'
import { businessesFilters } from '../../utils/filters'

const SLICE_KEY = 'businesses'

export const configureInitiallySelectedBusinesses = createAsyncThunk(
  `${SLICE_KEY}/configureInitiallySelectedBusinesses`,
  async () => {
    const data = await apiService.configureInitiallySelectedBusinesses()
    return data
  },
)

export const fetchBusinessTypes = createAsyncThunk(
  `${SLICE_KEY}/fetchBusinessTypes`,
  async ({ filters }, { getState }) => {
    const { currentZone } = getState().global
    const { businessTypes } = await apiService.businessTypes(currentZone.businessMapperIndices, filters)
    return { businessTypes }
  },
)

export const fetchBusinessesSummary = createAsyncThunk(
  `${SLICE_KEY}/fetchBusinessesSummary`,
  async (args, { getState, dispatch }) => {
    dispatch(setIsLoadingSummary({ isLoading: true }))
    const { currentZone } = getState().global
    const summary = await apiService.businessesSummary(currentZone.businessMapperIndices)
    return { summary }
  },
)

export const fetchBusinesses = createAsyncThunk(
  `${SLICE_KEY}/fetchBusinesses`,
  async ({ filters, from }, { getState, dispatch }) => {
    if (from > 0) dispatch(setIsLoadingNextBatch({ isLoading: true }))

    const { currentZone } = getState().global
    if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

    try {
      const { businesses, total } = await apiService.businesses(currentZone, filters, from)

      const allowScrolling = businesses.length > 0

      dispatch(setGlobalLoading(false))
      dispatch(setIsLoadingNextBatch({ isLoading: false }))

      return {
        isScrolling: from > 0,
        allowScrolling,
        businesses,
        total,
      }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching businesses from API',
        }),
      )
    }
  },
)

export const fetchBusinessesForExport = createAsyncThunk(
  `${SLICE_KEY}/fetchBusinessesForExport`,
  async ({ filters, scrollId: scroll }, { getState, dispatch }) => {
    const { currentZone } = getState().global
    if (!currentZone || currentZone.name === DUMMY_ZONE_NAME) return {}

    try {
      const { businesses, scrollId } = await apiService.businessesExport(currentZone, filters, scroll)
      return {
        businesses,
        scrollId,
      }
    } catch (err) {
      dispatch(
        addAlert({
          type: 'danger',
          message: 'Unexpected error when fetching businesses from API',
        }),
      )
    }
  },
)

export const initialState = {
  isLoadingSummary: false,
  summary: {
    entityCounts: {},
    incomeCounts: {},
    topIndustries: [],
  },
  businessTypes: [],
  businesses: [],
  businessesForExport: [],
  scrollId: undefined,
  selectedBusinessesIds: [],
  filters: businessesFilters.reduce((acc, { key, initialValue }) => ({ ...acc, [key]: initialValue }), {}),
  displayedColumns: defaultDisplayedColumns,
  totalBusinesses: 0,
  from: 0,
  allowScrolling: true,
  isLoadingInitial: false,
  isLoadingNextBatch: false,
  initiallySelectedBusinesses: undefined,
  showMap: 0,
}

const businessesIds = businesses => businesses.map(business => business.id)

const slice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setIsLoadingInitial(state, { payload = {} }) {
      state.isLoadingInitial = payload.isLoading || false
    },
    setIsLoadingSummary(state, { payload = {} }) {
      state.isLoadingSummary = payload.isLoading || false
    },
    setIsLoadingNextBatch(state, { payload = {} }) {
      state.isLoadingNextBatch = payload.isLoading
    },
    applyFilters(state, { payload = {} }) {
      const { filters } = payload
      state.filters = filters
    },
    setDisplayedColumns(state, { payload = {} }) {
      const { columns = [] } = payload
      state.displayedColumns = columns

      localStorageService.displayedColumns = columns
    },
    setShowMap(state, { payload = {} }) {
      if (Object.keys(payload).length === 0) return
      const { showMap } = payload
      state.showMap = showMap
    },
    toggleSelectedBusiness(state, { payload = {} }) {
      const { businessId } = payload

      const nextSelected = state.selectedBusinessesIds.includes(businessId)
        ? state.selectedBusinessesIds.filter(selectedBusinessId => selectedBusinessId !== businessId)
        : [...state.selectedBusinessesIds, businessId]

      state.selectedBusinessesIds = nextSelected
    },
    toggleSelectAllBusinesses(state, { payload = {} }) {
      const { isAllSelected } = payload

      state.selectedBusinessesIds = isAllSelected ? businessesIds(state.businesses) : []
    },
  },
  extraReducers: {
    [fetchBusinessesSummary.fulfilled]: (state, { payload = {} }) => {
      const { summary } = payload

      state.summary = summary
      state.isLoadingSummary = false
    },
    [fetchBusinessTypes.fulfilled]: (state, { payload = {} }) => {
      const { businessTypes } = payload
      state.businessTypes = businessTypes
    },
    [fetchBusinesses.fulfilled]: (state, { payload = {} }) => {
      if (_.isEqual(payload, {})) return
      const { isScrolling, businesses, total, allowScrolling } = payload

      state.businesses = isScrolling ? [...state.businesses, ...businesses] : businesses

      state.totalBusinesses = total
      state.allowScrolling = allowScrolling
      state.isLoadingInitial = false

      state.from = state.businesses.length
    },
    [fetchBusinessesForExport.fulfilled]: (state, { payload = {} }) => {
      if (_.isEqual(payload, {})) return
      const { businesses, scrollId } = payload
      state.businessesForExport = businesses
      if (businesses.length !== 0) state.scrollId = scrollId
      else state.scrollId = undefined
    },
    [configureInitiallySelectedBusinesses.fulfilled]: (state, { payload = {} }) => {
      const { initiallySelectedBusinesses } = payload
      state.initiallySelectedBusinesses = initiallySelectedBusinesses
    },
  },
})

export const {
  setIsLoadingInitial,
  setIsLoadingSummary,
  setIsLoadingNextBatch,
  setShowMap,
  applyFilters,
  setDisplayedColumns,
  toggleSelectedBusiness,
  toggleSelectAllBusinesses,
} = slice.actions

export const businessesReducer = slice.reducer
export default slice
