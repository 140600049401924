import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SummaryLoader from 'components/SummaryLoader'
import messages from './messages'
import styles from './SummarySection.scss'

const SummarySection = ({ isLoading, name, showHeader = true, children }) => (
  <section className={cx(styles.section, styles[name])}>
    {isLoading ? (
      <SummaryLoader />
    ) : (
      <>
        {showHeader && (
          <h2 className={styles.header}>
            <FormattedMessage {...messages.headers[name]} />
          </h2>
        )}
        <div className={cx(styles.content, styles[name])}>{children}</div>
      </>
    )}
  </section>
)

SummarySection.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
}

SummarySection.defaultProps = {
  isLoading: false,
}

export default SummarySection
