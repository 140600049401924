import { createSelector } from 'reselect'
import { initialState } from './slice'

const selectGlobalState = state => state.global || initialState

export const selectMapBaseUrl = createSelector(selectGlobalState, state => state.mapBaseUrl)

export const selectMapZoomDelta = createSelector(selectGlobalState, state => state.mapZoomDelta)

export const selectPlatform = createSelector(selectGlobalState, state => state.platform)

export const selectStaticFilesUrl = createSelector(selectGlobalState, state => state.staticFilesUrl)

export const selectIsInitialized = createSelector(selectGlobalState, state => state.isInitialized)

export const selectIsGlobalLoading = createSelector(selectGlobalState, globalState => globalState.isGlobalLoading)

export const selectIsLoadingZones = createSelector(selectGlobalState, globalState => globalState.isLoadingZones)

export const selectIsLoadingOrganizations = createSelector(
  selectGlobalState,
  globalState => globalState.isLoadingOrganizations,
)

export const selectIsLoadingUsers = createSelector(selectGlobalState, globalState => globalState.isLoadingUsers)

export const selectIsLoggingIn = createSelector(selectGlobalState, globalState => globalState.isLoggingIn)

export const selectCurrentUser = createSelector(selectGlobalState, globalState => globalState.currentUser)

export const selectIsLoggedIn = createSelector(selectGlobalState, globalState => !!globalState?.currentUser?.email)

export const selectCurrentUserStatus = createSelector(selectGlobalState, globalState => globalState.currentUserStatus)

export const selectAlerts = createSelector(selectGlobalState, globalState => globalState.alerts)

export const selectUserRoles = createSelector(selectGlobalState, globalState => globalState.userRoles)

export const selectAllZones = createSelector(selectGlobalState, globalState => globalState.allZones)

export const selectDashboardTransmissions = createSelector(
  selectGlobalState,
  globalState => globalState.dashboardTransmissions,
)

export const selectCurrentZone = createSelector(selectGlobalState, globalState => globalState.currentZone)

export const selectOrganizations = createSelector(selectGlobalState, globalState => globalState.organizations)

export const selectCurrentOrganization = createSelector(
  selectGlobalState,
  globalState => globalState.currentOrganization,
)

export const selectUsers = createSelector(selectGlobalState, globalState => globalState.users)

export const platforms = createSelector(selectGlobalState, globalState => {
  return globalState.platforms
})

export const selectAvailableZones = createSelector(selectGlobalState, globalState => globalState.availableZones)

export const selectUserPlatform = createSelector(selectGlobalState, globalState => globalState.userPlatform)

export const selectGraphData = createSelector(selectGlobalState, globalState => globalState.graphData)

export const selectIsUserPlatformInitialized = createSelector(
  selectGlobalState,
  globalState => globalState.isUserPlatformInitialized,
)
