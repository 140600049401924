import React, { FC } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import GenericText from 'components/common/GenericText'
import { formatNumber } from 'utils/formatters'
import GenericHeading from 'components/common/GenericHeading'
import { useTheme, SxProps } from '@mui/material/styles'

interface FinancialRowProps {
  heading: string
  revenues: (number | null)[]
  valueSx?: SxProps
}

const getValue = (value: string | number | null) => {
  if (typeof value === 'string') return value
  if (typeof value === 'number') return formatNumber(value)
  return 'N/A'
}

const FinancialRow: FC<FinancialRowProps> = ({ heading, revenues, valueSx = {} }) => {
  const theme = useTheme()
  return (
    <Stack direction='row' justifyContent='space-between' spacing={2}>
      <Box>
        <GenericHeading
          sx={{
            color: '#475467',
            fontWeight: 600,
            fontFamily: 'Source Sans Pro',
            fontSize: { lg: 11, xl: 13, xlplus: 20, xxxl: 32 },
            width: { lg: 74, xl: 92, xlplus: 130, xxxl: 200 },
            [theme.breakpoints.down('xl')]: {
              width: '74px',
              fontSize: '12px',
            },
          }}
        >
          {heading}
        </GenericHeading>
      </Box>
      <Stack component='ul' direction='row' sx={{ flexBasis: '80%', justifyContent: 'space-evenly' }}>
        {revenues.map((revenue, index) => (
          <Stack
            component='li'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            key={`revenue-${index}`}
          >
            <GenericText
              sx={{
                fontFamily: 'Dosis',
                fontSize: 16,
                width: 80,
                textAlign: 'center',
                [theme.breakpoints.down('xl')]: {
                  fontSize: 12,
                  width: 60,
                },
                [theme.breakpoints.up('xlplus')]: {
                  fontSize: 20,
                },
                [theme.breakpoints.up('xxxl')]: {
                  fontSize: 30,
                  width: 120,
                },
                ...valueSx,
              }}
            >
              {getValue(revenue)}
            </GenericText>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default FinancialRow
