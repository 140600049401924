import React, { FC } from 'react'
import Income from 'components/Income'
import Stack from '@mui/material/Stack'
import GenericText from 'components/common/GenericText'

interface VerticalBARevenueProps {
  revenue: number | null
}

const VerticalBARevenue: FC<VerticalBARevenueProps> = ({ revenue }) => (
  <Stack sx={{ pt: { lg: '8px', xxxl: '40px' } }}>
    <GenericText sx={{ fontSize: { xs: 12, xl: 14, xxl: 16, xxxl: 32 }, color: '#7F8897' }}>Revenue</GenericText>
    <GenericText
      sx={{ fontSize: { xs: 18, xl: 22, xxl: 24, xxxl: 46 }, fontFamily: 'Dosis', letterSpacing: '0.4px' }}
      withTitle={false}
    >
      {revenue ? <Income value={revenue} /> : 'N/A'}
    </GenericText>
  </Stack>
)

export default VerticalBARevenue
