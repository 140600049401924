import React from 'react'
import { ReactSVG } from 'react-svg'
import Indicator, { IIndicatorType } from 'components/Indicator'
import cx from 'classnames'
import style from './Icon.scss'

export type SIZES = 'large' | 'regular' | 'xsmall' | 'small' | 'medium' | 'huge' | 'xl'

export interface IIcon {
  name: string
  path?: 'assets' | 'rentals'
  title?: string
  size?: SIZES
  direction?: 'left' | 'right'
  className?: string
  contentClassName?: string
  children?: React.ReactNode
  svgClassName?: string
}

const Icon = ({
  name,
  path = 'assets',
  title,
  size = 'large',
  direction = 'left',
  children,
  className,
  contentClassName,
  svgClassName,
}: IIcon) => {
  return (
    <span
      title={title}
      className={cx(style.icon, style[`icon-size-${size}`], style[`icon-direction-${direction}`], className)}
    >
      {name.includes('indicator') ? (
        <Indicator type={name.replace('indicator-', '') as IIndicatorType} size={size} />
      ) : (
        <ReactSVG src={require(`./${path}/${name}.svg`)} className={cx(style.svg, svgClassName)} />
      )}
      {children && <span className={cx(style.content, contentClassName, style[name.slice(0, 9)])}>{children}</span>}
    </span>
  )
}

export default Icon
