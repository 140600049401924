import axios from 'services/axios'
import qs from 'qs'
import apiService from './api'

export const fetchVerticalEntity = async (entityId: string, zoneEndpoint: string) => {
  const endpoint = 'vertical'
  if (!entityId || !zoneEndpoint) return

  const params = {
    entityId,
    zoneEndpoint,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchVerticalDocumentActivity = async (
  entityId: string,
  filters: Record<string, string>[],
  from: number,
  zoneEndpoint: string,
  period?: { start: string; end: string },
  size?: number,
  activityType?: string | string[],
  sort?: string | qs.ParsedQs | string[] | qs.ParsedQs[],
) => {
  const endpoint = 'vertical/documentedActivity'
  if (!entityId || !zoneEndpoint) return

  const params = {
    entityId,
    zoneEndpoint,
    period,
    filters: JSON.stringify(filters),
    from,
    size,
    activityType,
    sort,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchVerticalDocumentActivityStats = async (
  entityId: string,
  zoneEndpointActivity: string,
  zoneEndpointEntity: string,
  period?: { start: string; end: string },
  activityType?: string | string[],
  filters?: string,
) => {
  const endpoint = 'vertical/documentedActivityStats'
  if (!entityId || !zoneEndpointActivity || !zoneEndpointEntity) return

  const params = {
    entityId,
    zoneEndpointActivity,
    zoneEndpointEntity,
    period,
    activityType,
    filters,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchVerticalRevenueBySources = async (
  entityId: string,
  period: { start: string; end: string },
  zoneEndpointActivity: string,
) => {
  const endpoint = 'vertical/financialsPieCharts'
  if (!entityId || !zoneEndpointActivity || !period) {
    console.error('Missing required params')
    return
  }

  const params = {
    entityId,
    zoneEndpoint: zoneEndpointActivity,
    period,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)

    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchVerticalEstimatedRevenue = async (
  entityId: string,
  period: { start: string; end: string },
  zoneEndpointActivity: string,
) => {
  const endpoint = 'vertical/financialsBarCharts'
  if (!entityId || !zoneEndpointActivity || !period) {
    console.error('Missing required params')
    return
  }

  const params = {
    entityId,
    zoneEndpoint: zoneEndpointActivity,
    period,
  }
  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)

    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}

export const fetchGraphData = async (entityId: string, zoneEndpoint: string) => {
  if (!entityId || !zoneEndpoint) return

  const endpoint = 'graph'

  const params = {
    entityId,
    zoneEndpoint,
  }

  const config = {
    params,
  }

  try {
    const { data } = await axios.get(endpoint, config)
    return data
  } catch (err) {
    apiService.handleApiError(err as {} | undefined)
  }
}
