import React from 'react'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'
import { Grid } from 'components/Grid'
import RentalsListCell from 'components/rentals/RentalsListCell'
import Loader from 'components/Loader'
import Input from 'components/Input'
import { SECTION_HOST, rowHeight, sizeToGridHeight } from 'containers/Rentals/specs'
import Icon from 'components/Icon'
import messages from './messages'
import styles from './RentalsList.scss'

export default function RentalsList(props) {
  const {
    listType,
    isGridLoading,
    isLoadingNextBatch,
    height,
    columns,
    items,
    selectedIds,
    highlightedItemId,
    onScroll,
    onSelectAll,
    onItemSelect,
    onRowClick,
    onSortClick,
    activeSort,
  } = props

  const isAllSelected = selectedIds.length > 0 && selectedIds.length === items.length
  const isSomeSelected = selectedIds.length > 0 && selectedIds.length !== items.length

  const handleSelectAllClick = () => {
    if (isSomeSelected) {
      onSelectAll(false)
      return
    }

    onSelectAll(!isAllSelected)
  }

  const RentalsListHeaderCell = ({ cell: { key, isSortable = false } }) => (
    <div className={cx(styles.headerCell, styles[key])}>
      <h5 className={styles.secondaryTitle}>
        <FormattedMessage {...messages.columns[key]} />
        {isSortable && (
          <Icon
            name='sort'
            size='regular'
            className={cx(styles.icon, {
              [styles.isActive]: key === activeSort,
            })}
          />
        )}
      </h5>
      {key === 'select' && (
        <Input type='checkbox' checked={isAllSelected} allowDeselect={isSomeSelected} onChange={handleSelectAllClick} />
      )}
    </div>
  )

  return (
    <div className={styles.container}>
      <Grid
        isLoading={isGridLoading}
        withGroupHeaders
        withHeaders
        fixedRowCount={2}
        columns={columns}
        rowHeight={rowHeight}
        height={height}
        rows={items}
        onCellClick={(columnIndex, item) => columnIndex !== 0 && onRowClick(item)}
        selectedRows={selectedIds}
        highlightedRow={highlightedItemId}
        className={styles.grid}
        innerClassName={styles.gridInner}
        onHeaderClick={onSortClick}
      >
        {(item, column, rowIndex, isHeader = false) =>
          isHeader ? (
            <RentalsListHeaderCell cell={column} />
          ) : (
            <RentalsListCell
              listType={listType}
              item={item}
              cellName={column.key}
              isChecked={column.key === 'select' && selectedIds.includes(item.id)}
              onSelect={onItemSelect}
            >
              {column.key === 'select' && rowIndex === items.length - 10 && <Waypoint onEnter={onScroll} />}
            </RentalsListCell>
          )
        }
      </Grid>
      {isLoadingNextBatch && <Loader type='scrolling' className={styles.loader} />}
    </div>
  )
}

RentalsList.propTypes = {
  listType: PropTypes.string,
  isGridLoading: PropTypes.bool,
  isLoadingNextBatch: PropTypes.bool,
  height: PropTypes.number,
  columns: PropTypes.array,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIds: PropTypes.array,
  highlightedItemId: PropTypes.string,
  onScroll: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  onSortClick: PropTypes.func,
  activeSort: PropTypes.string,
}

RentalsList.defaultProps = {
  listType: SECTION_HOST,
  isGridLoading: false,
  isLoadingNextBatch: false,
  height: sizeToGridHeight.small,
  columns: [],
  selectedIds: [],
  onRowClick: () => {},
  onSortClick: () => {},
  activeSort: 'numberOfListings',
}
