import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import BarChart from 'components/BarChart'
import Button from 'components/Button'
import messages from './messages'
import styles from './RentalsAnalyticsDistribution.scss'

const RentalsAnalyticsDistribution = ({ type, data, className, clickOnGraph, graphClear, clearFiltersFromGraph }) => (
  <div className={cx(styles.container, className)}>
    <h3 className={styles.header}>
      <FormattedMessage {...messages[type].header} />
      {graphClear && (
        <section className={styles.actions}>
          <Button color='link' onClick={clearFiltersFromGraph} className={styles.clear}>
            <FormattedMessage {...messages.clear} />
          </Button>
        </section>
      )}
    </h3>
    <div className={styles.chart}>
      <h4 className={cx(styles.label, styles.xLabel)}>
        <FormattedMessage {...messages[type].yAxisLabel} />
      </h4>
      <h4 className={cx(styles.label, styles.yLabel)}>
        <FormattedMessage {...messages[type].xAxisLabel} />
      </h4>
      <BarChart
        data={data}
        tooltipLabel={<FormattedMessage {...messages[type].tooltip} />}
        clickOnGraph={clickOnGraph}
      />
    </div>
  </div>
)

RentalsAnalyticsDistribution.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  className: PropTypes.string,
  clickOnGraph: PropTypes.func,
  graphClear: PropTypes.bool,
}

RentalsAnalyticsDistribution.defaultProps = {
  data: [],
  graphClear: false,
}

export default RentalsAnalyticsDistribution
