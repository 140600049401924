import moment from 'moment'
import { VerticalEntity } from 'store/verticals/types'
import { Period } from 'utils/types'
import { byYearValueIsExist } from 'containers/VerticalPage/common/utils'

const numberIsBetween = (value: number, start: number, end: number) => value >= start && value <= end

const getYearFromPeriod = (period: Period) => {
  const startYear = moment(period.start).year()
  const endYear = moment(period.end).year()

  return {
    startYear,
    endYear,
  }
}

export const getVerticalRevenueBySourcesFromEntity = (entityData: VerticalEntity | null, period: Period) => {
  if (!entityData) return []

  const { startYear, endYear } = getYearFromPeriod(period)

  const sources = entityData.revenueSources.map(source => ({
    name: source.platform,
    value: (() => {
      let totalSum = 0
      const totalData = source.revenueByYear?.total

      for (const key in totalData) {
        if (
          Object.prototype.hasOwnProperty.call(totalData, key) &&
          numberIsBetween(parseInt(key), startYear, endYear)
        ) {
          totalSum += totalData[key]
        }
      }

      return totalSum
    })(),
  }))

  let revenueSum = {}

  sources.forEach(source => {
    revenueSum = {
      ...revenueSum,
      [source.name]: source.value + (revenueSum?.[source.name] || 0),
    }
  })

  const sourcesSum = Object.keys(revenueSum).map(key => ({
    name: key,
    value: revenueSum[key],
  }))

  return sourcesSum
}

export const getVerticalRevenueByTotalFromEntity = (entityData: VerticalEntity | null, period: Period) => {
  if (!entityData) return []

  const { startYear, endYear } = getYearFromPeriod(period)

  let exactSum = 0
  let extrapolatedSum = 0

  entityData.revenueSources.forEach(source => {
    const exactData = source.revenueByYear?.exact
    const extrapolatedData = source.revenueByYear?.extrapolated

    for (const key in exactData) {
      if (
        Object.prototype.hasOwnProperty.call(exactData, key) &&
        numberIsBetween(parseFloat(key), startYear, endYear)
      ) {
        exactSum += exactData[key]
      }
    }

    for (const key in extrapolatedData) {
      if (
        Object.prototype.hasOwnProperty.call(extrapolatedData, key) &&
        numberIsBetween(parseFloat(key), startYear, endYear)
      ) {
        extrapolatedSum += extrapolatedData[key]
      }
    }
  })

  return [
    { name: 'Documented', value: exactSum },
    { name: 'Estimated', value: extrapolatedSum },
  ]
}

export const getTotalRevenueFromEntity = (entityData: VerticalEntity | null, period: Period) => {
  if (!entityData) return []

  const { startYear, endYear } = getYearFromPeriod(period)
  const data = entityData.totals.byYear
  const years = new Set<string>()

  const withoutVatIsExist = byYearValueIsExist(data, 'totalWithoutVat')

  for (const category in data) {
    Object.keys(data[category]).forEach(year => years.add(year))
  }

  const result = Array.from(years)
    .sort()
    .map(year => {
      const yearDataObject: {
        date: string
        exact: number
        extrapolated: number
        total: number
        reported: number
        unreported: number
        withoutVat?: number
      } = {
        date: year,
        exact: data?.exact?.[year] || 0,
        extrapolated: data?.extrapolated?.[year] || 0,
        total: data?.total?.[year] || 0,
        reported: data?.reportedByYear?.[year] || 0,
        unreported: data?.unReportedByYear?.[year] || 0,
      }

      if (withoutVatIsExist) {
        yearDataObject.withoutVat = data?.totalWithoutVat?.[year] || 0
      }

      return yearDataObject
    })
    .filter(item => numberIsBetween(parseFloat(item.date), startYear, endYear))

  return result
}

export const getLabelsForFinancialOverview = (
  totalRevenueGraphData: {
    [key: string]: string | number
  }[],
) => {
  const values = [
    { key: 'total', value: 'Total Revenue' },
    { key: 'reported', value: 'Reported' },
    { key: 'unreported', value: 'Unreported' },
  ]

  if (totalRevenueGraphData.some(item => typeof item['withoutVat'] === 'number')) {
    values.push({ key: 'withoutVat', value: 'Revenue w/o vat' })
  }

  return values
}
