import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import GridActions from 'components/GridActions'
import { searchableColumns } from 'components/rentals/RentalsHosts/specs'
import { useSelector } from 'react-redux'
import { selectHostsFilters, selectTotalHosts } from 'store/rentals'
import messages from './messages'

function RentalsActions({
  intl,
  toggleableColumns,
  displayedColumns,
  onSelection,
  onSearch,
  isHostDisplayed,
  onFieldsChange,
  search,
  searchFields,
  setSearch,
  isShareDisabled,
  onExport,
  setSearchFields,
}) {
  const onToggleColumns = selections => {
    const nextColumns = selections.map(selection => selection.value)
    onSelection(nextColumns)
  }

  const filters = useSelector(selectHostsFilters)
  const totalHosts = useSelector(selectTotalHosts)

  return (
    <GridActions
      withShare
      search={search}
      searchFields={searchFields}
      setSearch={setSearch}
      setSearchFields={setSearchFields}
      onSearch={onSearch}
      allColumns={toggleableColumns.map(column => ({
        value: column,
        label: intl.formatMessage(messages.columns[column]),
      }))}
      displayedColumns={displayedColumns}
      onColumnsChange={totalHosts > 0 ? onToggleColumns : () => {}}
      searchableFields={searchableColumns.map(column => ({
        value: column,
        label: intl.formatMessage(messages.columns[column]),
      }))}
      onShare={onExport}
      isShareDisabled={isShareDisabled}
      onFieldsChange={onFieldsChange}
      filters={filters}
      isReducedSearchSize={isHostDisplayed}
    />
  )
}

RentalsActions.propTypes = {
  intl: PropTypes.object.isRequired,
  toggleableColumns: PropTypes.array,
  displayedColumns: PropTypes.array,
  onSelection: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  isShareDisabled: PropTypes.bool.isRequired,
}

RentalsActions.defaultProps = {
  toggleableColumns: [],
  displayedColumns: [],
}

export default injectIntl(RentalsActions)
