import React from 'react'

const ZoomMinus = () => (
  <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.1649 7.22183C15.1649 10.8539 12.0635 13.8397 8.18327 13.8397C4.30306 13.8397 1.20166 10.8539 1.20166 7.22183C1.20166 3.58971 4.30316 0.602905 8.18327 0.602905C12.0634 0.602905 15.1649 3.58971 15.1649 7.22183Z'
      fill='#EFF2F6'
      stroke='#4682FF'
    />
    <path
      d='M11.3005 7.22073L4.44238 7.22073'
      stroke='#4682FF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ZoomMinus
