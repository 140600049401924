import { FocusEvent, MouseEvent } from 'react'
import moment from 'moment'
import type { DropdownOption } from 'store/verticalsDetails/types'

export type HandleOptionClickFunc = (event: MouseEvent<HTMLElement>, optionIndex: number) => void
export type ToggleDropdownFunc = (
  e: MouseEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement> | FocusEvent<HTMLDivElement>,
) => void

export const getDefaultDateValues = () => {
  return {
    start: moment('2000/01/01').format('YYYY/MM/DD'),
    end: moment().format('YYYY/MM/DD'),
  }
}

export const getHeadingValues = (options: DropdownOption[]) => {
  let text = ''
  let revenue = 0
  let activities = 0

  const isAllOptionsUnchecked = options.every(option => !option?.isChecked)
  if (isAllOptionsUnchecked) {
    text = 'REVENUE SOURCE: ALL'
  } else {
    const selectedOptions = options.filter(option => option?.isChecked)
    if (selectedOptions.length > 1) {
      text = `${selectedOptions.length} REVENUE SOURCES`
    } else {
      text = selectedOptions[0]?.revenueSource || ''
    }
  }

  options.forEach(option => {
    if (isAllOptionsUnchecked) {
      activities += option?.count || 0
      revenue += option?.revenueValue || 0
    } else if (option.isChecked) {
      activities += option?.count || 0
      revenue += option?.revenueValue || 0
    }
  })

  return {
    text,
    revenue,
    activities,
  }
}

export const getUrlPageValues = (): string[] => window.location.pathname.split('/').splice(1)

export const byYearValueIsExist = (
  byYear: {
    [key: string]: {
      [key: number]: string
    }
  },
  key: string,
) => {
  return byYear?.[key] && typeof byYear?.[key] === 'object' && Object.keys(byYear)?.length > 0
}
