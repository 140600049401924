import React, { useCallback, useEffect, useState } from 'react'
import axios from 'services/axios'
import cx from 'classnames'
import { Form, FormGroup, Label } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import OtpInput, { InputProps } from 'react-otp-input'
import Input from 'components/Input'
import Button from 'components/Button'
import messages from './messages'
import styles from './OTPForm.scss'

interface OTPFormProps {
  onSubmit: (code: string) => void
  className?: string
  invalid: boolean
  numberOfInputs?: number
}
const OTPForm = ({ onSubmit, className, invalid, numberOfInputs = 6 }: OTPFormProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [MFAImage, setMFAImage] = useState()
  const [OTPCode, setOTPCode] = useState('')

  const postSubmit = useCallback(() => {
    setIsSubmitted(true)
    onSubmit(OTPCode)
  }, [OTPCode, onSubmit])

  const onSubmitForm = (e: React.SyntheticEvent) => {
    e.preventDefault?.()
    postSubmit()
  }

  const invalidOTPCode = (isSubmitted && !OTPCode.length) || invalid
  const getOTPResources = useCallback(async () => {
    const { data } = await axios.get('auth/otp-resources')
    setMFAImage(data.mfaImage)
  }, [])

  useEffect(() => {
    getOTPResources()
  }, [getOTPResources])

  // useEffect(() => {
  //   if (OTPCode.length === 6) {
  //     postSubmit()
  //   }
  // }, [OTPCode.length, postSubmit])

  const errorStyles = invalidOTPCode ? { border: '1px solid #dc3545' } : {}

  return (
    <Form onSubmit={onSubmitForm} className={cx(styles.form, className)}>
      {MFAImage && (
        <div className={styles['otp-container']}>
          <p>Please scan the qr code below using your google authenticator app</p>
          <img src={MFAImage} alt='' />
        </div>
      )}
      <FormGroup>
        <Label for='OTPCode' className={styles.label}>
          <FormattedMessage {...messages.OTPCode} />
        </Label>
        {/* <Input */}
        {/*   id='OTPCode' */}
        {/*   name='OTPCode' */}
        {/*   value={OTPCode} */}
        {/*   onChange={value => setOTPCode(value)} */}
        {/*   invalid={invalidOTPCode} */}
        {/*   type='number' */}
        {/* /> */}
        <OtpInput
          value={OTPCode}
          onChange={setOTPCode}
          numInputs={numberOfInputs}
          renderSeparator={' '}
          inputStyle={{ width: 48, height: 48, marginRight: 20, ...errorStyles }}
          renderInput={(props: InputProps) => <input {...props} />}
        />
      </FormGroup>
      <FormGroup className={styles.submit}>
        <Button type='submit' color='primary' outline className='' disabled={OTPCode.length < numberOfInputs}>
          <FormattedMessage {...messages.submit} />
        </Button>
      </FormGroup>
    </Form>
  )
}

export default OTPForm
