import React from 'react'
import cx from 'classnames'
import styles from './BusinessSource.scss'
import {
  allowedSources,
  clickableSourceToImageMap,
  disabledSourceToImageMap,
  ICON_PATHS,
  originalSourceToImageMap,
} from 'components/BusinessSource/specs'

export interface IBusinessSource {
  source: string
  isNFTEntityOverview?: boolean
  isHoverable?: boolean
  className?: string
  isDisabled?: boolean
  isClickable?: boolean
  isOriginal?: boolean
}

const BusinessSource = ({
  source,
  isHoverable,
  className,
  isClickable,
  isDisabled,
  isOriginal,
  isNFTEntityOverview,
}: IBusinessSource) => {
  const sourceToImageOptions = {
    isClickable: clickableSourceToImageMap,
    isOriginal: originalSourceToImageMap,
    isDisabled: disabledSourceToImageMap,
  }

  const getImagesPathByType = (source: string) => {
    if (isClickable) return sourceToImageOptions.isClickable[source]
    if (isOriginal) return sourceToImageOptions.isOriginal[source]
    if (isDisabled) return sourceToImageOptions.isDisabled[source]
  }

  const getGlobeImageByType = () => {
    const globeImageOptions = {
      isClickable: require(`${ICON_PATHS.CLICKABLE}/globe.svg`),
      isDisabled: require(`${ICON_PATHS.DISABLED}/globe.svg`),
      isOriginal: require(`${ICON_PATHS.ORIGINAL}/globe.svg`),
    }

    if (isClickable) return globeImageOptions.isClickable
    if (isDisabled) return globeImageOptions.isDisabled
    if (isOriginal) return globeImageOptions.isOriginal
  }

  const sourceLower = source.toLowerCase()
  if (!allowedSources.includes(sourceLower))
    return (
      <span className={className}>
        <img
          src={getGlobeImageByType()}
          alt=''
          className={cx(styles.image, {
            [styles.hoverable]: isHoverable,
            [styles.color]: isOriginal,
            [styles.nftEntityIcon]: isNFTEntityOverview,
          })}
        />
      </span>
    )
  return (
    <span className={className}>
      <img
        src={getImagesPathByType(sourceLower)}
        alt={source}
        className={cx(styles.image, {
          [styles.hoverable]: isHoverable,
          [styles.color]: isOriginal,
          [styles.nftEntityIcon]: isNFTEntityOverview,
        })}
      />
    </span>
  )
}

export default BusinessSource
