import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Button from 'components/Button'
import Icon from 'components/Icon'
import style from './Map.scss'

const fillOpacity = 1

const MARKER_WEIGHT_BASE = 0.05
const MAX_ZOOM = 10

const allowedWidgets = ['zoomLevel']
const [WIDGET_ZOOM_LEVEL] = allowedWidgets
const _ = require('lodash')

@autobind
class Map extends Component {
  static propTypes = {
    baseUrl: PropTypes.string.isRequired,
    zoomDelta: PropTypes.number,
    widgets: PropTypes.array,
    locations: PropTypes.array,
    onZoom: PropTypes.func,
  }

  static defaultProps = {
    zoomDelta: 1,
    widgets: [],
  }

  constructor(props) {
    super(props)

    this.markersOnMap = []
    this.mapId = Math.floor(Math.random() * 100).toString()

    this.state = {
      currentZoom: null,
    }
  }

  componentDidMount() {
    const { baseUrl, zoomDelta, platform = 'saas' } = this.props

    const zoom = 4
    const maxZoom = 9
    const maxNativeZoom = 19
    const bounds = new L.LatLngBounds(new L.LatLng(49.5, -11.3), new L.LatLng(61.2, 2.5))

    this.map = L.map(this.mapId, {
      center: bounds.getCenter(),
      zoom,
      zoomControl: false,
      minBounds: bounds,
      maxBoundsViscosity: 1.0,
      zoomSnap: zoomDelta,
      zoomDelta,
      minZoom: 2,
      renderer: L.canvas(),
      worldCopyJump: true,
    })

    L.control
      .zoom({
        position: 'bottomright',
      })
      .addTo(this.map)

    const tileLayersURL =
      platform === 'prem' ? `http://${baseUrl}/tile/{z}/{x}/{y}.png` : `https://{s}.${baseUrl}/{z}/{x}/{y}.png`

    L.tileLayer(tileLayersURL, {
      detectRetina: true,
      maxZoom,
      maxNativeZoom,
      opacity: 0.5,
    }).addTo(this.map)

    this.map.on('zoomend', async e => {
      const currentZoom = this.map.getZoom()
      const { onZoom = () => {}, pointsMode = true } = this.props
      const { currentZoom: stateZoom } = this.state
      if (!stateZoom || stateZoom === currentZoom) {
        this.setState({ currentZoom })
        return
      }
      if (!pointsMode) {
        const bounds = this.map.getBounds()
        const top_left = bounds.getNorthWest()
        const bottom_right = bounds.getSouthEast()
        this.markersOnMap.forEach(marker => this.map.removeLayer(marker))
        this.markersOnMap.splice(0, this.markersOnMap.length)
        onZoom({ top_left, bottom_right, precision: currentZoom })
      }

      this.setState({ currentZoom })
    })

    // this.map.on('dragend', async e => {
    //   const currentZoom = this.map.getZoom();
    //   const { onZoom = () => {}, pointsMode = true } = this.props;
    //   if (!pointsMode) {
    //     const bounds = this.map.getBounds();
    //     const top_left = bounds.getNorthEast().wrap();
    //     const bottom_right = bounds.getSouthWest().wrap();
    //     this.markersOnMap.forEach(marker => this.map.removeLayer(marker));
    //     this.markersOnMap.splice(0, this.markersOnMap.length);
    //     onZoom({ top_left, bottom_right, precision: currentZoom });
    //   }
    // });

    this.didFitBounds = false
  }

  componentDidUpdate(prevProps) {
    this.map.invalidateSize()

    const { locations, pointsMode = true } = this.props

    if (
      prevProps.locations === locations ||
      (pointsMode && _.isEqual(prevProps.locations, locations) && this.markersOnMap.length > 0) ||
      (locations && locations[0].list && locations.reduce((acc, value = 0) => acc + value.list.length, 0) === 0)
    ) {
      this.renderMarkers()
      return
    }

    if ((!this.didFitBounds && locations && locations[0].list) || pointsMode) {
      const points = this.allLocations.map(item => L.point(item))

      if (L.bounds(points) && L.bounds(points).max) {
        const bounds = [
          [L.bounds(points).max.x, L.bounds(points).max.y],
          [L.bounds(points).min.x, L.bounds(points).min.y],
        ]
        if (!pointsMode)
          this.map.fitBounds(
            [
              [90, -180],
              [-90, 180],
            ],
            { maxZoom: 2 },
          )
        else this.map.fitBounds(bounds)
      }
      this.didFitBounds = true
      if (!pointsMode) return
    }

    this.renderMarkers()
  }

  componentWillUnmount() {
    this.didFitBounds = false
    this.markersOnMap.forEach(marker => this.map.removeLayer(marker))
    this.markersOnMap.splice(0, this.markersOnMap.length)
  }

  filterPoints = item =>
    !!item &&
    ((typeof item === 'string' && !!item.length) ||
      (!!item.coordinates && !!item.coordinates.length) ||
      !!item.enrichedListings)

  convertToPoint = item =>
    typeof item === 'string'
      ? item.split(',')
      : item.enrichedListings
      ? item.enrichedListings
      : item.coordinates.split(',')

  async updateView() {
    const currentZoom = this.map.getZoom()
    const { onZoom = () => {}, pointsMode = true } = this.props
    if (pointsMode) return
    const bounds = this.map.getBounds()
    const top_left = bounds.getNorthWest()
    const bottom_right = bounds.getSouthEast()
    this.markersOnMap.forEach(marker => this.map.removeLayer(marker))
    this.markersOnMap.splice(0, this.markersOnMap.length)
    onZoom({ top_left, bottom_right, precision: currentZoom })
  }

  get allLocations() {
    const { locations = [] } = this.props
    if (!locations) return []
    const locationsList = locations
      .map(({ list }) => list && list.filter(this.filterPoints).map(this.convertToPoint))
      .flat()
    return locationsList
  }

  get locationsByType() {
    const { locations = [] } = this.props

    return locations.map(({ list, listColor }) => {
      const markers = list.filter(this.filterPoints)

      return {
        color: listColor,
        markers,
      }
    })
  }

  render() {
    const { widgets, pointsMode = true } = this.props
    const { currentZoom } = this.state

    const shouldDisplayButton = !pointsMode
    // @TODO: Refactor
    const formattedZoomLevel = 100 + currentZoom * 10

    const withZoomLevel = widgets.includes(WIDGET_ZOOM_LEVEL)

    return (
      <div
        className={cx(style.container, {
          [style.withZoomLevel]: withZoomLevel,
        })}
      >
        <div id={this.mapId} className={style.map}>
          {shouldDisplayButton && (
            <Button className={cx(style.refresh)} onClick={() => this.updateView()}>
              <Icon name='reload' size='regular'>
                <span className={cx(style.text)}>Search this area</span>
              </Icon>
            </Button>
          )}
        </div>
        {withZoomLevel && <div className={style.zoomLevel}>{formattedZoomLevel}%</div>}
      </div>
    )
  }

  renderMarkers() {
    this.markersOnMap.forEach(marker => this.map.removeLayer(marker))
    this.markersOnMap.splice(0, this.markersOnMap.length)

    this.markersOnMap = this.locationsByType
      .map(({ color, markers }) => this.renderMarkersByType(markers, color))
      .flat()
  }

  getRadius = (marker, zoom) => {
    let min = 6
    if (zoom >= 12) min = 4 + 2 * (zoom - 12)
    if (typeof marker === 'string' || !marker.count) return min
    const max = 20
    const candidate = min + marker.count / 100
    if (candidate > max) return max
    if (candidate < min) return min
    return candidate
  }

  getPoint(childCount) {
    // if (childCount === 1) {
    //   return new L.Point(10, 10);
    // }
    if (childCount < 10) {
      return new L.Point(25, 25)
    }
    if (childCount < 100) {
      return new L.Point(32, 32)
    }
    if (childCount < 1000) {
      return new L.Point(39, 39)
    }
    if (childCount < 10000) {
      return new L.Point(46, 46)
    }
    if (childCount < 100000) {
      return new L.Point(53, 53)
    }
    return new L.Point(60, 60)
  }

  renderMarkersByType = (markers, color) => {
    const renderer = L.canvas()
    const zoom = this.map.getZoom()
    const { pointsMode = true } = this.props
    const relevantMarkers = markers.splice(0, 1000) // TODO - REMOVE ME
    return relevantMarkers.map(marker => {
      let myIcon
      if (!pointsMode && zoom < 14) {
        const childCount = marker.count || 1
        let c = ' marker-cluster-'
        // if (childCount === 1) {
        //   c += 'single';
        if (childCount < 10) {
          c += 'single'
        } else if (childCount < 100) {
          c += 'tens'
        } else if (childCount < 1000) {
          c += 'hundreds'
        } else if (childCount < 10000) {
          c += 'thousands'
        } else if (childCount < 100000) {
          c += 'tens-thousands'
        } else {
          c += 'huge'
        }

        // const myIcon = L.divIcon({
        //   html: `<div class="custom-marker" style="background-color:${color}"><span></span></div>`,
        //   // html:'<div class="map-label '+instanceclass+'"><div class="map-label-content">'+icontext+'</div><div class="map-label-arrow"></div></div>
        //   className: '',
        //   iconSize: null
        myIcon = new L.DivIcon({
          html: `<div><span>${childCount}</span></div>`,
          className: `marker-cluster${c}`,
          iconSize: this.getPoint(childCount),
        })
      } else {
        myIcon = new L.DivIcon({
          html: `<div class="custom-marker" style="background-color:${color}"><span></span></div>`,
          className: '',
          iconSize: null,
        })
      }
      const newMarker = L.marker(this.convertToPoint(marker), { icon: myIcon })
      if (typeof marker !== 'string') {
        if (marker.originalLocation) {
          newMarker.bindTooltip(`${marker.originalLocation}: ${marker.count}`)
        } else if (marker.hostSiteId) {
          newMarker.bindTooltip(
            `<div> <img src=${require('./assets/user.png')} height="20rem"/> ${marker.hostName}, Host ID: ${
              marker.hostSiteId
            } <br/> &nbsp<img src=${require('./assets/pin.jpg')} height="20rem"/> ${
              marker.listingAddressEnriched
            } <div>`,
          )
          newMarker.on('click', e => {
            const { clickOnMarker } = this.props
            clickOnMarker(marker.hostSiteId)
          })
        } else if (marker.listingAddressEnriched) {
          newMarker.bindTooltip(
            `<div> Listing ID: ${marker.listingId} <br/> <img src=${require('./assets/pin.jpg')} height="20rem"/> ${
              marker.listingAddressEnriched
            } <div>`,
          )
        } else if (marker.count) {
          // newMarker.bindTooltip(marker.count.toString(), {
          //   permanent: true,
          //   direction: 'center',
          //   className: 'text'
          // });
        }
      }

      newMarker.addTo(this.map)
      return newMarker
    })
  }
}

export default Map
