import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import 'react-input-range/lib/css/index.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './MonthPicker.scss'

const calendarIcon = require('./assets/calendar.svg')

export default function MonthPicker({
  start,
  end,
  onChange,
  isNFT = false,
  isCharts = false,
  format = 'MMM yyyy',
  popperPlacement = 'bottom-start',
  iconUrl,
  containerClassName,
  iconClassName,
  showYearPicker,
}) {
  const [startDate, setStartDate] = useState(new Date(start))
  const [endDate, setEndDate] = useState(new Date(end))

  useEffect(() => {
    setEndDate(new Date(end))
  }, [end])

  useEffect(() => {
    setStartDate(new Date(start))
  }, [start])

  return (
    <div
      className={cx(
        styles.container,
        { [styles.NFTVersion]: isNFT, [styles.chartsVersion]: isCharts },
        containerClassName,
      )}
    >
      <img src={iconUrl || calendarIcon} className={cx(styles.calendarIcon, iconClassName)} alt='calendar' />
      <DatePicker
        selected={startDate}
        onChange={date => {
          setStartDate(date)
          onChange({ startDate: date, endDate })
        }}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat={format}
        showMonthYearPicker={!showYearPicker}
        showYearPicker={showYearPicker}
        popperPlacement={popperPlacement}
      />
      <span className={styles.separator}>-</span>
      <DatePicker
        selected={endDate}
        onChange={date => {
          setEndDate(date)
          onChange({ startDate, endDate: date })
        }}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        dateFormat={format}
        showMonthYearPicker={!showYearPicker}
        showYearPicker={showYearPicker}
        popperPlacement={popperPlacement}
      />
    </div>
  )
}

MonthPicker.propTypes = {
  start: PropTypes.any.isRequired,
  end: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  isNFT: PropTypes.bool,
  isCharts: PropTypes.bool,
  format: PropTypes.string,
  iconUrl: PropTypes.string,
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  showYearPicker: PropTypes.bool,
  popperPlacement: PropTypes.string,
}
