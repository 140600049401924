import Button from 'components/Button'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './TableActionsBar.scss'

interface ExportCSVButtonProps {
  exportMessages: FormattedMessage.MessageDescriptor
  isExportDisabled: boolean
  onCSVExport: () => void
}

const ExportCSVButton: FC<ExportCSVButtonProps> = ({ exportMessages, isExportDisabled, onCSVExport }) => (
  <Tooltip title='Export Entity Details CSV'>
    <Button color='light' outline className={styles.export} disabled={isExportDisabled} onClick={onCSVExport}>
      <Icon name='share' size='regular'>
        <FormattedMessage {...exportMessages} />
      </Icon>
    </Button>
  </Tooltip>
)

export default ExportCSVButton
